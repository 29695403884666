import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import "./Our_company.css"

import Our_company_menu from './Our_company_menu'
import Vission from './Vission'


const Our_company = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <Our_company_menu/>

            <div className='container-fluid our_cmp_top_bg'>
                <div className='container'>
                    <h1>Mercedes-Benz Financial Services</h1>
                    <h1>Always there for you</h1>
                </div>
            </div>
            <div className="container abt_mer_para">
                <p className="abt_us_hm">All about us</p>
                <hr className="wb_adapter_hr"></hr>
                <h1>Mercedes-Benz Financial Services</h1>
                <p className="para_1_fr">Mercedes-Benz Financial Services India Private Limited (MBFS India), formerly Known as Daimler Financial Services  India Private Limited (DFS India), is a wholly owned subsidiary of MBGAG , Germany, incorporated in the year 2010  as a Private Limited Company. MBFS India is also a Non Deposit Accepting – Systemically Important Non-Banking  Finance Company (“NBFC-ND-SI”) registered with the Reserve Bank of India (“RBI”).</p>
                <p className="para_2_fr">MBFS India carries on the activities of 'Finance' which includes operating lease and financing of vehicles to end customers and dealership related financial services, like inventory financing for MBGAG franchised dealers.Everything from a single source-Finance and Insurance Solution for your Mercedes-Benz</p>
            </div>
            <Vission/>
            <Footer />
        </div>
    )
}

export default Our_company