import React from 'react'
import "./Our_prod.css"


const Prod_card = (props) => {
    return (
        <div>
            <div className='card_bdy'>
                <div className='crd_img_o_u'>
                    <img src={props.imga} alt="" />
                </div>
                <div className='crd_bdy_cnt'>
                    <p className='main_hed_o_p'>{props.title}</p>
                    <p className='sub_para_o_p'>{props.cardpara}</p>
                </div>
                <div className='o_u_p_btn'>
               <a target="_blank" href={props.redirect}><button className='btn_oor_prd'>{props.cardbtn}</button></a>
                </div>
            </div>
        </div>
    )
}

export default Prod_card