import React, { useEffect, useState } from "react";
import AuthService from "../../service/authservice"; 
import { getFileIcon,getFileExtension } from "../CommonService/fileDetails";
const Intimation_s_e = ({year}) => {
    const [IntimationToStockExchange,SetIntimationToStockExchange]=useState([]);
    const[FilterSEYear,SetFilterSEYear]=useState("All");
    let [fileDownload, SetFileDownload] = useState({
        base64String: "",
        fileType: "",
        fileName: "",
      });
    useEffect(()=>{
        GetIntimationToSE();
    },[])
    useEffect(()=>{
      GetIntimationToSE();
    },[year])
    useEffect(() => {
        if (fileDownload.base64String != null && fileDownload.base64String != "") {
          let a = document.createElement("A");
          a.href =
            `data:${fileDownload.fileType};base64,` + fileDownload.base64String;
          a.download = fileDownload.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }, [fileDownload]);
    const GetIntimationToSE=()=>{
      AuthService.GetServiceCall("MbfsReports/IntimationToStockExchange?SEYear="+year)
        .then((res)=>{
          if(res!=null){
          SetIntimationToStockExchange(res)
          }
        })
        .catch((er)=>{SetIntimationToStockExchange(null);})
    }
    let OnChangeDownloadHandler = (arg) => {
        let filename = arg;
        AuthService.GetServiceCall("MbfsReports/DownloadFile?FilePath=" + filename)
          .then((res) => {
            if(res!=undefined){
            SetFileDownload(res);
            }
          })
          .catch((err) => console.log("Error:", err));
      };
    return (
        <>     
        {IntimationToStockExchange !=null &&
            <div className='container int_ex_ch'>
            {IntimationToStockExchange.map((intimationItems,intimationId)=>{
               return  <div className='I_s_e_1' key={intimationId}>
                    <h6 className='int_sub_txt'>Intimation to Stock Exchange Year Of {intimationItems.year}</h6>
                    <div className="accordion" id="accordion_an_ir_1">
                    {intimationItems.stockExchange!=null &&
                        intimationItems.stockExchange.map((seItems,seId)=>{
                            return <div className="accordion-item accordion-item_an" key={seId}>
                            <button className="accordion-button ac_btn collapsed" data-bs-toggle="collapse" data-bs-target={`#collapseOne_se_${seItems.stockId}`}>
                                <h3>{seItems.headingTxt}<span>{seItems.monthFiles.length == 1 ? `(${seItems.monthFiles.length} file)`: `(${seItems.monthFiles.length} files)`}</span></h3>
                            </button>
                            <div id={`collapseOne_se_${seItems.stockId}`} className="accordion-collapse collapse" data-bs-parent="#accordion_an_ir_1">
                            <div className="accordion-body ar">
                                    <h3 className='brd_name'><span>Mercedes-Benz</span> Financial Services India</h3>
                                    <h1 className='ye_an'>{seItems.headingTxt}</h1>
                                    
                                    {seItems.monthFiles!=null &&
                                        seItems.monthFiles.map((montfileItems,mfId)=>{
                                            return <ul className='inside_st_ex' key={mfId}>
                                        <li>{montfileItems.heading}</li>
                                        <p className="dwn_pdf se">
                                        <a
                                          onClick={() =>
                                            OnChangeDownloadHandler(montfileItems.filePath)
                                          }
                                        >
                                          <i className={`fa-solid ${getFileIcon(montfileItems.filePath)}`}></i>
                                          <span>{getFileExtension(montfileItems.filePath)}</span>
                                        </a>
                                      </p>
                                    </ul>
                                        })
                                    }                              
                                </div>

                            </div>
                        </div>
                        })                  
                    }
                    </div>
                </div>
            })              
            }
            </div>
        }
        </>
    )
}

export default Intimation_s_e