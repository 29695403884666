import React from 'react'
import { NavLink } from 'react-router-dom'


const Our_company_menu = () => {

  const animate_1 = () => {
    var zmon = document.querySelectorAll(".top_tw_mnu_flt");
    var scroll = window.pageYOffset;

    for (var i = 0; i < zmon.length; i++) {
      if (scroll > 165) {
        zmon[i].classList.add("activeing");
      } else {
        zmon[i].classList.remove("activeing");
      }
    }
  }

  window.addEventListener("scroll", animate_1);



  return (



    <div>
      <div className='top_tw_mnu_flt'>
        <div className='container d-none d-md-block'>
          <div className='our_cmp_top_m'>
            <NavLink end className='our_cmp_l_n one' to='/Our_company' >About Us</NavLink>
            {/* <NavLink className='our_cmp_l_n' to='/Our_company/Vission' >Vision/Mission</NavLink> */}
            <NavLink className='our_cmp_l_n' to='/Our_company/Investor' >Investor Relations</NavLink>
            <NavLink end className='our_cmp_l_n' to='/Our_company/leadership' >Leadership team</NavLink>
          </div>
        </div>
        <div className='container fincial d-block d-md-none'>
        <p data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" ><span>Our Company</span><i className="fa-solid fa-angle-down"></i></p>
        </div>
      </div>
    </div>
  )
}

export default Our_company_menu