export const getFileExtension=(fileName)=>{
    let extension = fileName.split('.').pop();
    let getFileExtension="Download PDF";
    if(extension == "pdf"){
      getFileExtension="Download PDF";
      return getFileExtension;
    }
    else if(extension =="pptx" || extension=="ppt"){
      getFileExtension="Download PPT";
      return getFileExtension;
    }
    else if(extension =="xlsx" || extension=="xls"){
      getFileExtension="Download EXCEL";
      return getFileExtension;
    }
    else{
      return getFileExtension;
    }
  }
  export const getFileIcon=(argFilename)=>{
    if(argFilename!=null && argFilename!=""){
    let fileIcon=argFilename.split('.').pop();
    let fileIconClass="fa-file";
    if(fileIcon =="pdf")
    {
      fileIconClass="fa-file-pdf";
      return fileIconClass;
    }
    else{
      return fileIconClass;
    }
  }
  }
  export const getFY=()=> {
    let lstFY = [];
    let currentYear = new Date().getFullYear();
    for (let i = 2019; i <= currentYear; i++) {
        lstFY.push(i.toString() + '-' + (i + 1).toString());
    }
    return lstFY;
}
export const getSEYear=()=> {
  let lstFY = [];
  let currentYear = new Date().getFullYear();
  for (let i = 2019; i <= currentYear; i++) {
      lstFY.push(i.toString());
  }
  return lstFY;
}
export const getIntimationMonths=()=> {
  const lstMonths = [];
  for (let i = 1; i <= 12; i++) {
      const month = new Date(2000, i - 1, 1);
      const monthString = month.toLocaleString('en-US', { month: 'long' });
      lstMonths.push(monthString);
  }
  return lstMonths;
}
