import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import More_ifo_menu from './More_ifo_menu'
import "./For_info.css"


const Legal_notice = () => {
  return (
    <div>
      <Header />
      <Header_two />
      <More_ifo_menu />
      <div className='container-fluid legal_us_bg'>
        <div className='container'>
          <h1>Legal Notice</h1>
        </div>
      </div>
      <div className='container pad_cnt_mob'>
        <h1 className='cnt_us_main_hed'>Legal Notice</h1>
        <div className='section_not_legl'>
          <p className='sub_hed_fr_l_n'>Copyrights</p>
          <p className='legal_para'>Copyright 2023 Mercedes-Benz Financial Services. All Rights Reserved. The text, images, graphics, sound files, animation files, video files and their arrangement on Mercedes-Benz Financial Services Internet sites are all subject to Copyright and other intellectual property protection. These objects may not be copied for commercial use or distribution, nor may these objects be modified or reposted to other sites. Some Mercedes-Benz Financial Services Internet sites also contain images that are subject to the copyright rights of third party providers.</p>
        </div>
        <div className='section_not_legl'>
          <p className='sub_hed_fr_l_n'>Trademarks</p>
          <p className='legal_para'>Unless otherwise indicated, all marks displayed on Mercedes-Benz Financial Services Group Internet sites are subject to the trademark rights of Mercedes-Benz Financial Services, including each of Mercedes-Benz Financial Services's primary brands (Mercedes-Benz, Smart, Sterling, Freightliner, Setra), its model name plates, and its corporate logos and emblems.</p>
        </div>
        <div className='section_not_legl'>
          <p className='sub_hed_fr_l_n'>Product Variations</p>
          <p className='legal_para'>Some of the product information illustrations and images contained on this web site may have been prepared for generic use on Mercedes-Benz Financial Services web sites maintained in different countries around the world. Consequently, some of the information and/or accessories which are not available in some countries or which, in order to satisfy local market demand or regulatory controls in such countries, may only be available in different specifications or configurations.</p>
          <p className='legal_para'>If you are interested in any vehicle model, paint, option or accessory shown on the web site and are unsure of its availability or specification in your locality, you should contact your local Mercedes-Benz Financial Services office or a local authorised dealer for the relevant product, for information of current details in your locality.</p>
        </div>
        <div className='section_not_legl'>
          <p className='sub_hed_fr_l_n'>Prices</p>
          <p className='legal_para'>All prices specified are recommended maximum retail prices, exclusive of all government charges and dealer delivery costs and inclusive of GST. Prices are current at the time of publication and subject to change without notice.</p>
        </div>
        <div className='section_not_legl'>
          <p className='sub_hed_fr_l_n'>Finance Products</p>
          <p className='legal_para'>Information contained in summaries of finance products is subject to change at any time without notice. This may include indicative interest rates, terms available, estimated payments and fees and charges.
            If you are interested in any finance product, you should contact the local finance provider to obtain confirmation of current details.</p>
        </div>
        <div className='section_not_legl'>
          <p className='sub_hed_fr_l_n'>No Warranties or Representations</p>
          <p className='legal_para'>This information is provided by Mercedes-Benz Financial Services "as is" and to the extent permitted by law, is provided without warranty of any kind, expressed or implied, including (but not limited to) any implied warranties of merchantability, fitness for any particular purpose, or non-infringement. While the information provided is believed to be accurate, it may include errors or inaccuracies.</p>
        </div>
        <div className='section_not_legl last'>
          <p className='sub_hed_fr_l_n'>No Licenses</p>
          <p className='legal_para'>Mercedes-Benz Financial Services has sought to achieve an innovative and informative Internet site. We hope that you will be as enthusiastic as we are about this creative effort. However, you also need to understand that Mercedes-Benz Financial Services must protect its intellectual property, including its patents, trademarks and copyrights. Accordingly, you are hereby on notice that neither this internet site, nor any material contained therein shall in any way grant or be taken to grant any person a license to Mercedes-Benz Financial Services's intellectual property.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Legal_notice