import React from 'react'
import Footer from '../Footer';
import Header from '../Header';
import Header_two from '../Header_two';
import { NavLink } from "react-router-dom";
import "./Home_page.css";

const Home_page = () => {

    var Th_img = require('./Home_page_img/aboutus.jpg');
    var Th_img_2 = require('./Home_page_img/hm_img_2.png');
    var Th_img_3 = require('./Home_page_img/hm_img_3.png');
    return (

        <div>
            <Header />
            <Header_two />
            <div className='container-fluid top_banner_str'>
                <div className='container'>
                    <h1>Welcome to Mercedes-Benz Financial Services</h1>
                    <p>Discover our wide range of finance & insurance products.</p>
                </div>
            </div>
            <div className='container img_thr_exp'>
                <div className='row img_cards'>
                    <div className='col-lg-4 col-12 img_hm_sb'>
                        <div className='ma_of_lay_effce'>
                            <img className='high_light_img_txt' src={Th_img} />
                            <NavLink className='overlay_bdy'  to='/Our_company' >
                                    <p><i className="fa-solid fa-angle-right"></i><span>Read More</span></p>
                            </NavLink>
                        </div>
                        <h2>Our Company</h2>
                    </div>
                    <div className='col-lg-4 col-12 img_hm_sb'>
                        <div className='ma_of_lay_effce'>
                            <img className='high_light_img_txt' src={Th_img_2} />
                            <NavLink to='/Our_product' className='overlay_bdy'>
                                <p><i className="fa-solid fa-angle-right"></i><span>Read More</span></p>
                            </NavLink>
                        </div>
                        <h2>Our Product</h2>
                    </div>
                    <div className='col-lg-4 col-12 img_hm_sb'>
                        <div className='ma_of_lay_effce'>
                            <img className='high_light_img_txt' src={Th_img_3} />
                            <NavLink to='/More_info' className='overlay_bdy'>
                                <p><i className="fa-solid fa-angle-right"></i><span>Read More</span></p>
                            </NavLink>
                        </div>
                        <h2>For More Info</h2>
                    </div>
                </div>
            </div>
            <div className='container abt_mer_para'>
                <p className='abt_us_hm'>All about us</p>
                <hr className="wb_adapter_hr"></hr>
                <h2>Mercedes-Benz Financial Services</h2>
                <p className='para_1_fr'>Mercedes-Benz Financial Services India Private Limited (MBFS India), formerly Known as Daimler Financial Services  India Private Limited (DFS India), is a wholly owned subsidiary of MBGAG , Germany, incorporated in the year 2010  as a Private Limited Company. MBFS India is also a Non Deposit Accepting – Systemically Important Non-Banking  Finance Company (“NBFC-ND-SI”) registered with the Reserve Bank of India (“RBI”).</p>
                <p className='para_2_fr'>MBFS India carries on the activities of 'Finance' which includes operating lease and financing of vehicles to end customers and dealership related financial services, like inventory financing for MBGAG franchised dealers.Everything from a single source-Finance and Insurance Solution for your Mercedes-Benz</p>
            </div>
            <Footer />
        </div>
    )
}

export default Home_page