// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid.Heder_two_str {
    background-color: #040707;
    color: #767676;
    font-size: 14px;
    border-top: 1px solid #4a4a4a;
}
.scn_ment_str {
    display: flex;
    gap: 60px;
}
.scn_ment_str a {
    margin-bottom: 0;
    text-decoration: none;
    color: #808080;
    font-family: MBcrop;
    font-size: 16px;
    font-weight: 600;
}
.scn_ment_str {
    padding: 25px 0px 25px 0px;
}
.active{
    color: #fff !important;
}
.scn_ment_str a:hover{
    color: #fff;
}
@media(max-width:767px){
    .scn_ment_str a{
    font-size: 12px;
}
.scn_ment_str {
    gap: 50px;
}
.container.header_tw_cs{
    display: none;
}
}`, "",{"version":3,"sources":["webpack://./src/Components/Header_two.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,6BAA6B;AACjC;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;AACA;IACI;IACA,eAAe;AACnB;AACA;IACI,SAAS;AACb;AACA;IACI,aAAa;AACjB;AACA","sourcesContent":[".container-fluid.Heder_two_str {\n    background-color: #040707;\n    color: #767676;\n    font-size: 14px;\n    border-top: 1px solid #4a4a4a;\n}\n.scn_ment_str {\n    display: flex;\n    gap: 60px;\n}\n.scn_ment_str a {\n    margin-bottom: 0;\n    text-decoration: none;\n    color: #808080;\n    font-family: MBcrop;\n    font-size: 16px;\n    font-weight: 600;\n}\n.scn_ment_str {\n    padding: 25px 0px 25px 0px;\n}\n.active{\n    color: #fff !important;\n}\n.scn_ment_str a:hover{\n    color: #fff;\n}\n@media(max-width:767px){\n    .scn_ment_str a{\n    font-size: 12px;\n}\n.scn_ment_str {\n    gap: 50px;\n}\n.container.header_tw_cs{\n    display: none;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
