import React from 'react'
import "./Footer.css"
import { NavLink } from "react-router-dom";

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    return (
        <div>
            <div className='container-fluid footer_bg_str'>
                <div className='container'>
                    <div onClick={scrollToTop} className='scroll_top_btn'>
                        <div className='top_sec'>
                            <i className="fa-sharp fa-solid fa-angle-up"></i>
                            <p>Up</p>
                        </div>
                    </div>
                    <div className='footer_link_st'>
                        <div className='our_cmp_s'>
                            <p>Our Company</p>
                            <ul className='links_ftr'>
                                <li><NavLink end to={"/Our_company"}>About us</NavLink></li>
                                <li><NavLink to={"/Our_company/Investor"}>Investor Relations</NavLink></li>
                                <li><NavLink to={"/Our_company/leadership"}>Leadership team</NavLink></li>
                                {/* <li><NavLink to={"/Our_company/Vission"}>Vision/Mission</NavLink></li> */}
                            </ul>
                        </div>
                        <div className='our_pro_s'>
                            <p>Our Product</p>
                            <ul className='links_ftr'>
                                <li><a className='our_prd_web' target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/financing-leasing.html">STAR Finance</a></li>
                                <li><a className='our_prd_web' target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/financing-leasing.html">STAR Agility +</a></li>
                                <li><a className='our_prd_web' target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/insurance.html">STAR Protect Insurance</a></li>
                                <li><a className='our_prd_web' target="_blank" href="https://mymbfs.com/maintenance">Customer Online Service</a></li>
                            </ul>
                        </div>
                        <div className='fr_mr_info'>
                            <p>For more info</p>
                            <ul className='links_ftr'>
                                <li><NavLink end to={"/More_info"}>Contact us</NavLink></li>
                                <li><NavLink to={"/More_info/Legal_notice"}>Legal notice</NavLink></li>
                                <li><NavLink to={"/More_info/Cookies"}>Cookies</NavLink></li>
                                <li><NavLink to={"/More_info/Privacy_s"}>Privacy statement</NavLink></li>
                                <li><NavLink to={"/More_info/Fradulent"}>Fraudulent Call Alert</NavLink></li>
                                <li><NavLink to={"/More_info/Framework_rs"}>Resolutions Framework 2.0</NavLink></li>
                                <li><NavLink to={"/More_info/FAQ"}>FAQs</NavLink></li>
                                <li><NavLink to={"/More_info/SARFAESI_ACT"}>Assets possessed under SARFAESI</NavLink></li>
                                <li><NavLink to={"/More_info/Other_document"}>Other Documents And Policies</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className='cpy_txt'>
                        <p><i className="fa-regular fa-copyright"></i>{currentYear}. Mercedes-Benz Financial Services India Pvt. Ltd. All Rights Reserved</p>
                        <span className='s_c_icon'>
                           {/* <a href=""> <i className="fa-brands fa-square-facebook"></i> </a> */}
                           <a target='_blank' href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGjjIWTYZqfOAAAAYdWjpaw7uak7cnAI5t8N9zHChWO_Pfx7swYzJDVk6YgiOeGgJu9eIK4E7F5-yyqDI79xG-Sxl-pFna2CY1-VcLosBqfDFwSFvoN7KT3-hJb6YibtDnaJyA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fmercedes-benz-financial-services-india%2F%3ForiginalSubdomain%3Din"> <i className="fa-brands fa-linkedin linked_fnt"></i> </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer