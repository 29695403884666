import React from 'react'
import Header from '../Header'
import Header_two from '../Header_two'
import More_ifo_menu from './More_ifo_menu'
import Footer from '../Footer'
import "./For_info.css"


const Sarfaesi_act = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className='container'>
              <h1 className='cnt_us_main_hed'>Information on secured assets possessed under the SARFAESI Act, 2002.</h1>

              <div className='table_sarfasi'>
                 <table className='dmcookies_tbl sarfasi'>
                    <thead>
                        <tr>
                            <th>Sl.No</th>
                            <th>Branch Name</th>
                            <th style={{width:'100px'}}>State</th>
                            <th>Borrower Name</th>
                            <th>Guarantor Name (wherever applicable)</th>
                            <th>Registered address of the Borrower</th>
                            <th>Registered address of the Guarantor (wherever applicable)</th>
                            <th>Outstanding amount as on 10.08.2021 (in ₹)</th>
                            <th>Asset Classification</th>
                            <th>Date of Asset classification</th>
                            <th>Details of security possessed</th>
                            <th>Name of the Title holder of the security possessed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Ernakulum</td>
                            <td>Kerala</td>
                            <td>Rajasree Motors Private Limited</td>
                            <td>Gangu Swamy Rajan, Sreenivasan Sivakumar, Sreenivasan Krishnakumar, Manikandan Automobile Private Limited</td>
                            <td>39/3842 55/2311 Alappat Road Ravipuram Ernakulam - 682016 Kerala</td>
                            <td>Manikanda Nivas, K.S.N. Menon Road, M.G. Road, P.O. Cochin 16, Ernakulam 682016, Kerala</td>
                            <td>46,69,64,169.21 as on 10.08.2021</td>
                            <td>NPA</td>
                            <td>30.08.2021</td>
                            <td>Property survey no. 130/1 old survey no 268/1 &2, door no. 8/406, 8/407, 8/408 Village Maradu, Dist ward 8, Ernakulam.</td>
                            <td>Manikandan Automobile Private Limited</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Rajkot </td>
                            <td>Gujarat</td>
                            <td>Sealand Infrastructure Private Limited</td>
                            <td>Ashish Alex, Mary Alex, Alex Louis</td>
                            <td>20, Galaxy Commercial Centre, Jawahar Road, Rajkot, Gujarat - 360001 </td>
                            <td>Dia/1, Tapovan Society, Akshar Marg, Rajkot, Gujarat.; Ashish Villa 9, Mahavir Society, Nirmala Convent Road, Rajkot, Gujarat.</td>
                            <td>33,44,57,682.23 as on 17.09.2021</td>
                            <td>NPA</td>
                            <td>30.11.2020</td>
                            <td>Commercial Property known as “Sealand Trucking” Situated at Moti Chirai R. S. No. 142/2, National Highway 8-A, Village: Moti Chirai, Taluka: Bhachau, District: Kutch (Gujarat), Paldi.</td>
                            <td>Sealand Infrastructure Private Limited</td>
                        </tr>
                    </tbody>
                 </table>
              </div>
            </div>
            <Footer />
        </div>
    )
}

export default Sarfaesi_act



