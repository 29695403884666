import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import "./Our_prod.css"
import Prod_card from './Prod_card'

const Our_prod = () => {

    const animate_1 = () => {
        var zmon = document.querySelectorAll(".top_tw_mnu_flt");
        var scroll = window.pageYOffset;

        for (var i = 0; i < zmon.length; i++) {
            if (scroll > 165) {
                zmon[i].classList.add("activeing");
            } else {
                zmon[i].classList.remove("activeing");
            }
            console.log(scroll)
            console.log(zmon)
        }
    }

    window.addEventListener("scroll", animate_1);


    var O_pr_img = require('./Our_prod_img/Our_prod_1.png');
    var O_pr_img_1 = require('./Our_prod_img/Our_prod_2.png');
    var O_pr_img_2 = require('./Our_prod_img/Our_prod_3.png');
    var O_pr_img_3 = require('./Our_prod_img/Our_prod_4.png');
    var O_pr_img_4 = require('./Our_prod_img/Our_prod_5.png');
    var O_pr_img_5 = require('./Our_prod_img/Our_prod_6.png');

    return (
        <div>
            <Header />
            <Header_two />
            <div className='container-fluid top_tw_mnu_flt '>
                <div className='container d-none d-md-block'>
                    <div className='our_cmp_top_m'>
                        <a className='our_cmp_l_n a_shar' target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/financing-leasing.html">STAR Finance</a>
                        <a className='our_cmp_l_n a_shar' target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/financing-leasing.html">STAR Agility +</a>
                        <a className='our_cmp_l_n a_shar' target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/insurance.html">STAR Protect Insurance</a>
                        <a className='our_cmp_l_n a_shar' target="_blank" href="https://mymbfs.com/maintenance">Customer Online Service</a>
                    </div>
                </div>
                <div className='container fincial d-block d-md-none'>
                    <p data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop_2"><span>Our Product</span><i className="fa-solid fa-angle-down"></i></p>
                </div>
            </div>
            <div className='container-fluid our_prod_bg'>
                <div className='container'>
                    <h1>Everything from a single source</h1>
                </div>
            </div>
            <div className='container pad_cnt_mob'>
                <h1 className='our_p_tp_hd'>Finance and Insurance solutions for your Mercedes</h1>
                <p className='our_p_tp_p'>Discover the world of Mercedes-Benz with our financing products of enjoy maximum flexibility with our assured buyback program.get an overview here and find the solution that suits you best.</p>
            </div>
            <div className='container pad_cnt_mob'>
                <div className='row_1_o_p'>
                    <div className='card_inside_str'>
                        <Prod_card imga={O_pr_img} title={"STAR Finance"} cardpara={"Flexible and tailor-made finance options"} cardbtn={"Check your Eligibility"} redirect={" https://consumer.experian.in/ecv-jet/affinityFlowController/affinityFlow?affinityId=186"}  />
                    </div>
                    <div className='card_inside_str'>
                        <Prod_card imga={O_pr_img_1} title={"STAR Agility +"} cardpara={"Assured Buy-Back value with Inclusive maintenance and extended warranty"} cardbtn={"Contact us"} redirect={"https://www.mercedes-benz.co.in/passengercars/finance/finance-form.html"} />
                    </div>
                    <div className='card_inside_str extra'>
                        <Prod_card imga={O_pr_img_2} title={"STAR Protect Insurance"} cardpara={"Protecting your STAR for secured driving experience"} cardbtn={"Request a Quote"} redirect={"https://www.mercedes-benz.co.in/passengercars/content-pool/marketing-pool/contact-forms/aem-forms-new/insurance-form/insurance-form.html"} />
                    </div>
                </div>
                <div className='row_1_o_p two'>
                    <div className='card_inside_str'>
                        <Prod_card imga={O_pr_img_3} title={"Retention"} cardpara={"Special Benefits For loyal Customers"} cardbtn={"Contact us"}  redirect={"https://www.mercedes-benz.co.in/passengercars/finance/finance-form.html"}/>
                    </div>
                    <div className='card_inside_str extra_t'>
                        <Prod_card imga={O_pr_img_4} title={"Customer online Services"} cardpara={"With my MBFS,you can enjoy a virtual Mercedes-Benz ownership experience"} cardbtn={"Vist Here"} redirect={"https://mymbfs.com/"} />
                    </div>
                    <div className='card_inside_str extra lst'>
                        <Prod_card imga={O_pr_img_5} title={"Advance Assurance"} cardpara={"All the care and support your STAR deserves,with extended warranty"} cardbtn={"Contact us"} redirect={"https://www.mercedes-benz.co.in/passengercars/finance/finance-form.html"} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Our_prod


