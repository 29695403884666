import React, { useState } from 'react'
import './Chat_bot.css'
const Chat_bot = () => {
    var botgif = require('../Chat_bot/Images/chatbot.gif');
    const [botshow, Setbotshow] = useState(false)
    const botshowfun = () => {
        Setbotshow(true)
    }
    const botclose = () => {
        Setbotshow(false)
    }
    return (
        <div>
            {
                botshow === false && <img onClick={botshowfun} src={botgif} alt='bot_chat' className='bot_chat' />
            }
            <div className={`frame_fram ${botshow ? 'adding_animate' : ''}`}>
                <i onClick={botclose} className="fa-solid fa-xmark xmark"></i>
                <iframe className="frame_over_on" src="https://isd-dia-cars.adc-apac.corpintra.net/62300/index_en.html"></iframe>
            </div>
        </div >
    )
}

export default Chat_bot