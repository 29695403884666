import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import "./For_info.css"
import More_ifo_menu from './More_ifo_menu'

const Cookies = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className='container pad_cnt_mob'>
              
                <h1 className='cnt_us_main_hed'>Cookies</h1>
                <div className='cookie_para'>
                    <p>Mercedes-Benz Financial Services uses cookies to record the preferences of visitors, to enable us to optimize the design of our web site. Cookies are small files which are stored on your hard drive. They ease navigation, and increase the user-friendliness of a web site. Cookies also help us to identify the most popular sections of our web site. This enables us to provide content that is more accurately suited to your needs, and in so doing improve our service. Cookies can be used to determine whether there has been any contact between us and your computer in the past. Only the cookie on your computer is identified. Personal details can be saved in cookies, provided that you have consented to this, for example, in order to facilitate secure online access so that you not need to enter your user ID and password again.</p>
                    <p>If you choose not to accept cookies, you can still visit our website. Most browsers automatically accept cookies. You can prevent cookies from being stored on your hard drive by setting your browser to not accept cookies. The exact instructions for this can be found in the manual for your browser. You can delete cookies already on your hard drive at any time. However, if you choose not to accept cookies it may result in a reduced availability of the services provided by our web site.</p>
                    <p>For the purpose of statistical analysis Mercedes-Benz Financial Services uses Adbobe Omniture products (Omniture SiteCatalyst, Omniture DataWarehouse, Omniture Discover und Omniture SearchCenter). If you do not wish Mercedes-Benz Financial Services to collect and analyze statistical data of your visit you may object for the future use of your data at any time (opt-out).</p>
                    <p>For the technical implementation of your objection it is necessary to install an opt-out cookie on your browser. This cookie will only indicate that you have opted-out. Please note that for technical reasons the opt-out cookie will only affect the browser you object from. If you delete the cookies in your browser or use a different device or browser respectively, you will need to opt-out again.<a target='_blank' href="http://www.d1.sc.omtrdc.net/optout.html?omniture=1&popup=1&locale=en_US&second=1&second_has_cookie=0">Please click here to install the opt-out cookie.</a></p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cookies