// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_strt {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.frm_skelton {
    width: 420px;
    padding: 40px 30px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 1);
}

.container-fluid.frm_bg {
    background-color: #040707;
    height: 100vh;
}

.email_dv {
    margin-bottom: 30px;
}

button.sign_btn {
    width: 100%;
    color: #fff;
    background: #040707;
    border: none;
    font-weight: 600;
    padding: 7px 0px;
    border-radius: 5px;
    margin-top: 10px;
}

.form_strt h3 {
    color: #fff;
    margin-bottom: 25px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

p.f_y_p_txt {
    margin-top: 25px;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
}

.nav_img img {
    width: 20%;
    margin-left: 40px;
    margin-top: 30px;
}

@media (max-width:767px) {
    .frm_skelton {
        width: 100%;
    }

    .form_strt {
        height: 100vh;
    }

    .nav_img img {
        width: 40%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Login_form.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,mDAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".form_strt {\n    margin-top: 50px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.frm_skelton {\n    width: 420px;\n    padding: 40px 30px;\n    border-radius: 5px;\n    background-color: #fff;\n    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 1);\n}\n\n.container-fluid.frm_bg {\n    background-color: #040707;\n    height: 100vh;\n}\n\n.email_dv {\n    margin-bottom: 30px;\n}\n\nbutton.sign_btn {\n    width: 100%;\n    color: #fff;\n    background: #040707;\n    border: none;\n    font-weight: 600;\n    padding: 7px 0px;\n    border-radius: 5px;\n    margin-top: 10px;\n}\n\n.form_strt h3 {\n    color: #fff;\n    margin-bottom: 25px;\n    font-weight: 600;\n    letter-spacing: 0.5px;\n}\n\np.f_y_p_txt {\n    margin-top: 25px;\n    color: #fff;\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n.nav_img img {\n    width: 20%;\n    margin-left: 40px;\n    margin-top: 30px;\n}\n\n@media (max-width:767px) {\n    .frm_skelton {\n        width: 100%;\n    }\n\n    .form_strt {\n        height: 100vh;\n    }\n\n    .nav_img img {\n        width: 40%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
