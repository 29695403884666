import React, { useEffect, useState,useRef } from "react";
import AuthService from "../service/authservice";
import { getFY, getSEYear,getIntimationMonths} from "./CommonService/fileDetails";
const AddReport = (props) => {
  const [Errors,SetErrors] = useState("")
  const[Success,SetSuccess]=useState("")
  const [AnnualReportType, SetAnnualReportType] = useState(props.Report);
  const[ReportYear,SetReportYear]=useState("");
  const[ReportMonth,SetReportMonth]=useState("");
  const fileInputRef = useRef(null);
  const headingRef=useRef(null);
  const [SeYear,SetSeYears]=useState("")
  const[Heading,SetHeading]=useState("")
  const [SeMonths, SetSeMonths] = useState("");
  let [fileDetails, SetFileDetails] = useState({
    baseurl: "",
    contentType: "",
    contentLength: "",
    filename: "",
    filePath: "",
  });
  const myDate = new Date();
  const[InsertRes,SetInsertRes]=useState("");
  const isoString = myDate.toISOString();
  const [AnnualReportFiles, SetAnnualReportFiles] = useState({
    Id: 0,
    FY: "",
    FilePath: "",
    FileName: "",
    CreatedDate: isoString,
    HeaderText: "",
    ReportsTypeId: 0,
    Fymonth:""
  });
  const [IntimationToStockExchange, SetIntimationToStockExchange] = useState({
    id: 0,
    SEYear: "",
    SEMonth: "",
    FilePath: "",
    FileName: "",
    CreatedDate: isoString,
    Heading: "",
  });
  const [OtherDocuments, SetOtherDocuments] = useState({
    id: 0,
    Heading: "",
    Year:"",
    FilePath: "",
    FileName: "",
    CreatedDate: isoString,
    reportType:0,
  });
  let [file, setFile] = useState("");
  let SaveFile = (e) => {
    setFile(e.target.files[0]);
  };
  useEffect(()=>{
    GetSeYear()
    GetMonth()
    SetErrors("")
    SetSuccess("");
    SetAnnualReportFiles("")
    SetIntimationToStockExchange("")
    SetOtherDocuments("")
    clearHandler()
  },[props.Dropdown,props.Othersrep])
  useEffect(()=>{
    getYearVal()
    SetIntimationToStockExchange({
      ...IntimationToStockExchange,
      SEYear: props.YearSy,
      SEMonth:props.Month
    });
    SetAnnualReportFiles({
      ...AnnualReportFiles,
      FY:props.YearFy
    })
  },[props.YearFy,props.YearSy,props.Month])

  
useEffect(()=>{
 if(file!="")
   Upload()
},[file])
  useEffect(() => {
    if (props.Dropdown.Annualreport === true) {
      SetAnnualReportFiles({
        ...AnnualReportFiles,
        FileName: fileDetails.filename,
        FilePath: fileDetails.filePath,
      });
    } else if (props.Dropdown.IntimationToStockExchange === true) {
      SetIntimationToStockExchange({
        ...IntimationToStockExchange,
        FileName: fileDetails.filename,
        FilePath: fileDetails.filePath,
      });
    }
    else if (props.Dropdown.OtherDocumentsAndPolicies === true) {
      SetOtherDocuments({
        ...OtherDocuments,
        FileName: fileDetails.filename,
        FilePath: fileDetails.filePath,
      });
    }
  }, [fileDetails]);
  const GetSeYear=()=>{
    if(props.Dropdown.Annualreport===true || props.Othersrep ==="3"){
      // AuthService.GetServiceCall("MbfsReports/FinancialYears")
      // .then((res) => {
      //   SetSeYears(res);
      // })
      // .catch((err) => console.log("Error:", err));
      SetSeYears(getFY)
    }
    else if(props.Dropdown.IntimationToStockExchange === true)
    {
      // AuthService.GetServiceCall("MbfsReports/GetIntimationtionYears")
      // .then((res) => {
      //   SetSeYears(res);
      // })
      // .catch((err) => console.log("Error:", err));
      SetSeYears(getSEYear)
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies === true && props.Othersrep ==="1")
    {
      // AuthService.GetServiceCall("MbfsReports/GetIntimationtionYears")
      // .then((res) => {
      //   SetSeYears(res);
      // })
      // .catch((err) => console.log("Error:", err));
      SetSeYears(getSEYear)
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies === true && props.Othersrep ==="5")
    {
      // AuthService.GetServiceCall("MbfsReports/GetIntimationtionYears")
      // .then((res) => {
      //   SetSeYears(res);
      // })
      // .catch((err) => console.log("Error:", err));
      SetSeYears(getSEYear)
    }
  }
  const GetMonth=()=>{
    SetSeMonths(getIntimationMonths)
    // AuthService.GetServiceCall("MbfsReports/GetIntimationtionMonths")
    // .then((res) => {
    //   SetSeMonths(res);
    // })
    // .catch((err) => console.log("Error:", err));
  }
  let Upload = () => {
    let formData = new FormData();
    formData.append("formFile", file);
    const fileSize = formData.get('formFile').size;
    if (props.Dropdown.Annualreport === true) {
      formData.append("folderId", "1");
    } else if (props.Dropdown.IntimationToStockExchange) {
      formData.append("folderId", "2");
    }
    else if (props.Dropdown.OtherDocumentsAndPolicies) {
      formData.append("folderId", "3");
    }
    if(file ==="" || file ===null)
    {
      SetSuccess("")
      SetErrors("Please Upload File");
    }
    if (fileSize <= 10485760) {
      AuthService.PostServiceCallToken("MBFSAdmin/UploadFile", formData)
      .then((res) => {
        SetFileDetails(res);
        setFile("");
        SetErrors("")
        SetSuccess("File uploaded Successfully");
      })
      .catch((err) => {
        console.log("Error:", err);
        setFile("");
      });
    }
    else{
      SetSuccess("")
      SetErrors("File size must below 4 MB.");
    }
  
  }
  let SubmitAnnualReport = () => {
    if (props.Dropdown.Annualreport == true) {
      if (AnnualReportFiles.FY == "All" || AnnualReportFiles.FY == null || AnnualReportFiles.FY =="") {
        SetSuccess("")
        SetErrors("Please select Year");
      } else if (AnnualReportFiles.HeaderText == "" || AnnualReportFiles.HeaderText == null) {
        SetSuccess("")
        SetErrors(
          "Please fill Heading");
      } 
      else if (fileDetails.filename == ""  || fileDetails.filename == null) {
        SetSuccess("")
        SetErrors(
          "please upload file");
      } 
      else {
        SetErrors("");
        SetSuccess("");
        AuthService.PostServiceCallToken("MBFSAdmin/AnnualReports",AnnualReportFiles)
          .then((res) => {
            SetAnnualReportFiles("");
            SetFileDetails("");
            SetInsertRes(res);
            clearHandler();
            props.onchangeGet();
          })
          .catch((err) => console.log("Error:", err));
      }
    } else if (props.Dropdown.IntimationToStockExchange) {
      if (IntimationToStockExchange.SEYear == "All" || IntimationToStockExchange.SEYear == ""|| IntimationToStockExchange.SEYear == null) {
        SetSuccess("")
        SetErrors("please select Year");
      } 
      else if (IntimationToStockExchange.Heading == "" || IntimationToStockExchange.Heading == null) 
      {
        SetSuccess("")
        SetErrors("Please fill Heading");
      }
      else if(IntimationToStockExchange.SEMonth == "All" || IntimationToStockExchange.SEMonth == null || IntimationToStockExchange.SEMonth == ""){
        SetErrors("Please select month");
      }
       else if (fileDetails.filename == "" || fileDetails.filename == null) {
        SetSuccess("")
        SetErrors("Please upload file");
      } 
      else {
        SetErrors("");
        SetSuccess("")
        AuthService.PostServiceCallToken("MBFSAdmin/PostIntimationToSEYears",IntimationToStockExchange)
          .then((res) => {
            SetIntimationToStockExchange("");
            clearHandler();
            SetFileDetails("")
            props.onchangeSe();
          })
          .catch((err) => console.log("Error:", err));
      }
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies ==true && props.Othersrep !="2" && props.Othersrep !="4")
    {
      // if (props.Othersrep =="2") {
      //   console.log("varan 0");
      //   SetOtherDocuments({
      //     ...OtherDocuments,
      //     Year:"" ,
      //   });
      //   return true;
      // } 
      if (OtherDocuments.Year == "All" || OtherDocuments.Year=="" || OtherDocuments.Year==null) {
        if(props.Othersrep !="2" && props.Othersrep !="4"){
          SetSuccess("")
        SetErrors("Please select Year");
        }
      } 
      else if (OtherDocuments.Heading == "" || OtherDocuments.Heading == null) 
      {
        SetSuccess("")
        SetErrors(
          "Please fill Heading");
      }
      else if (fileDetails.filename == "" || fileDetails.filename == null) {
        SetSuccess("")
        SetErrors(
          "Please upload file");
      } 
      else{
        SetErrors("");
        SetSuccess("");
        AuthService.PostServiceCallToken("MBFSAdmin/PostOtherDocuments",OtherDocuments)
          .then((res) => {
            props.onchangeOthers();
            clearHandler();
            SetFileDetails("");
            SetOtherDocuments("");
          })
          .catch((err) => console.log("Error:", err));
      }
        
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies ==true && props.Othersrep =="2")
    {
       if (OtherDocuments.Heading == "" || OtherDocuments.Heading == null) 
      {
        SetSuccess("")
        SetErrors(
          "Please fill Heading");
      }
      else if (fileDetails.filename == "" || fileDetails.filename == null) {
        SetSuccess("")
        SetErrors(
          "Please upload file");
      } 
      else{
        SetErrors("");
        SetSuccess("");
        AuthService.PostServiceCallToken("MBFSAdmin/PostOtherDocuments",OtherDocuments)
          .then((res) => {
            props.onchangeOthers();
            clearHandler();
            SetFileDetails("");
            SetOtherDocuments("");
          })
          .catch((err) => console.log("Error:", err));
      }
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies ==true && props.Othersrep =="4")
    {
       if (OtherDocuments.Heading == "" || OtherDocuments.Heading == null) 
      {
        SetSuccess("")
        SetErrors(
          "Please fill Heading");
      }
      else if (fileDetails.filename == "" || fileDetails.filename == null) {
        SetSuccess("")
        SetErrors(
          "Please upload file");
      } 
      else{
        SetErrors("");
        SetSuccess("");
        AuthService.PostServiceCallToken("MBFSAdmin/PostOtherDocuments",OtherDocuments)
          .then((res) => {
            props.onchangeOthers();
            clearHandler();
            SetFileDetails("");
            SetOtherDocuments("");
          })
          .catch((err) => console.log("Error:", err));
      }
    }
  };
  let changeHandler = (e) => { 
    SetHeading(e.target.value)
    if (props.Dropdown.Annualreport == true) {
      SetAnnualReportFiles({
        ...AnnualReportFiles,
        HeaderText: e.target.value,
        ReportsTypeId:props.Report
      });
    } else if (props.Dropdown.IntimationToStockExchange == true) {
      SetIntimationToStockExchange({
        ...IntimationToStockExchange,
        Heading: e.target.value,
      });
    }
    else if (props.Dropdown.OtherDocumentsAndPolicies == true) {
      SetOtherDocuments({
        ...OtherDocuments,
        Heading: e.target.value,
        reportType:props.Othersrep
      });
    }
  };
  let clearHandler = () => {
    SetHeading("")    
    //props.onchangeGet();
    SetReportYear("All");
    SetReportMonth("All");
      document.getElementById('closeModal').click();
      fileInputRef.current.value = "";
      headingRef.current.value="";
    GetSeYear()
    GetMonth()
  }
  let closeHandler=()=>{
    SetHeading("")  
    document.getElementById('closeModal').click();
      fileInputRef.current.value = "";
      headingRef.current.value="";
      GetSeYear()
    GetMonth()
    SetErrors("")
  }
  const ReportYearChangeHandler=(e)=>{
    SetReportYear(e.target.value)
    
  }
  useEffect(()=>{
    if(props.Dropdown.Annualreport ===true)
    {
      SetAnnualReportFiles({
        ...AnnualReportFiles,
        FY: ReportYear
      });
    }   
    else if(props.Dropdown.IntimationToStockExchange===true){
      SetIntimationToStockExchange({
        ...IntimationToStockExchange,
        SEYear: ReportYear
      });
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies==true)
    {
      SetOtherDocuments({
        ...OtherDocuments,
        Year:ReportYear
      });
    }
  },[ReportYear])

  // useEffect(() => {
  //   if (props.Dropdown.Annualreport === true) {
  //     SetAnnualReportFiles((prev) => ({
  //       ...prev,
  //       FY: ReportYear
  //     }));
  //   } else if (props.Dropdown.IntimationToStockExchange === true) {
  //     SetIntimationToStockExchange((prev) => ({
  //       ...prev,
  //       SEYear: ReportYear
  //     }));
  //   } else if (props.Dropdown.OtherDocumentsAndPolicies == true) {
  //     SetOtherDocuments((prev) => ({
  //       ...prev,
  //       Year: ReportYear
  //     }));
  //   }
  // }, [ReportYear, props.Dropdown]);
  
  // Similar adjustment for the second useEffect
  
  const ReportMonthChangeHandler=(e)=>{
    SetReportMonth(e.target.value)
  }
  useEffect(()=>{
    if(props.Dropdown.IntimationToStockExchange===true){
      SetIntimationToStockExchange({
        ...IntimationToStockExchange,
        SEMonth:ReportMonth
      });
    }
    else if(props.Dropdown.Annualreport===true){
      SetAnnualReportFiles({
        ...AnnualReportFiles,
        Fymonth: ReportMonth
      });
    }
  },[ReportMonth])
  const getYearVal = () => {
    if (props.Dropdown.Annualreport ===true) {
      SetReportYear(props.YearFy);
    } 
    else if(props.Dropdown.IntimationToStockExchange===true) {
      SetReportYear(props.YearSy);
      SetReportMonth(props.Month);
    }
    else if(props.Dropdown.OtherDocumentsAndPolicies === true) {
      SetReportYear(props.Otheryear);
    }
  };
  return (
    <>
      <div className="upload_btn">
        <button data-bs-toggle="modal" data-bs-target="#uploadModal">
          <i className="fa-solid fa-upload"></i>
          <span>Add</span>
        </button>
      </div>
        <div
          className="modal fade"
          id="uploadModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content mdl_cnt">
              <div className="modal-body">
                <div className="inp_fld title_filed">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Heading
                  </label>
                  <input
                    type="text"
                    className="form-control frm_cnt"
                    id="HeadingTxt"
                    ref={headingRef}
                    onChange={changeHandler}
                  />
                </div>
                {props.Dropdown.IntimationToStockExchange === true || props.Dropdown.Annualreport ===true || props.Othersrep =="1" || props.Othersrep =="5" || props.Othersrep =="3" ?(
                <div className="inp_fld title_filed">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Year
                  </label>
                    <select className="form-select" id="yearSelect" value={ReportYear}  onChange={(e)=>ReportYearChangeHandler(e)}>
                       <option value="All">Select Year</option>
                      {SeYear!="" &&
                        SeYear.map((seYears,seId)=>{
                             return <option value={seYears} key={seId}>{seYears}</option>
                          })
                      }
                  </select>
                  </div>
                  ):null}
                  {props.Dropdown.IntimationToStockExchange === true &&
                       <div className="inp_fld title_filed">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Month
                           </label>
                           <select className="form-select" value={ReportMonth} id="monthSelect" onChange={(e)=>ReportMonthChangeHandler(e)}>
                              <option value="All">Select Month</option>
                             {SeMonths!="" &&
                              SeMonths.map((seMonths,seMonthId)=>{
                                   return <option value={seMonths} key={seMonthId}>{seMonths}</option>
                                      })
                              }  
                           </select>
                        </div>
                  }
                  {props.Dropdown.Annualreport === true && props.Report ==="2"  &&
                       <div className="inp_fld title_filed">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Month
                           </label>
                           <select className="form-select" value={ReportMonth} id="monthSelect" onChange={(e)=>ReportMonthChangeHandler(e)}>
                              <option value="All">Select Month</option>
                             {SeMonths!="" &&
                              SeMonths.map((seMonths,seMonthId)=>{
                                   return <option value={seMonths} key={seMonthId}>{seMonths}</option>
                                      })
                              }  
                           </select>
                        </div>
                  }
                <div className="inp_fld title_filed">
                  <div>
                    <label htmlFor="formFile" className="form-label">
                      Upload File
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      ref={fileInputRef}
                      id="formFile"
                      onChange={(e) => {
                        SaveFile(e);
                      }}
                    />
                  </div>
                  <span
                    className="mt-4"
                    style={{ color: "red" }}
                    id="validationTxt"
                  >{Errors}</span>
                  <span
                    className="mt-4"
                    style={{ color: "green" }}
                  >{Success}</span>
                </div>
              </div>
              <div className="modal-fo">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="closeModal"
                  onClick={closeHandler}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={SubmitAnnualReport}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default AddReport;
