import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import More_ifo_menu from './More_ifo_menu'
import "./For_info.css"

const More_info = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className='container-fluid cont_us_bg'>
                <div className='container'>
                    <h1>Contact Us</h1>
                </div>
            </div>
            <div className='container pad_cnt_mob'>
                <h1 className='cnt_us_main_hed'>Contact Us</h1>
                <div className="company-info">
                    <p className='cnt_main_p'>Registered Address</p>
                    <p>Mercedes-Benz Financial Services India Private Limited</p>
                    <p>5th Floor, Plot 8,</p>
                    <p>Baashyam Willow Square 9 &amp; 10,</p>
                    <p>First Street,  Thiru ViKa Industrial Estate,</p>
                    <p>Guindy,</p>
                    <p>Chennai - 600032</p>
                    <p>Tamil Nadu - India</p>
                </div>
                <div className="company-info_f_corporate">
                    <p className='cnt_main_p two'>Corporate Address</p>
                    <p>Mercedes-Benz Financial Services India Private Limited</p>
                    <p>E-3, MIDC Chakan, Phase III, Chakan Industrial Area,</p>
                    <p>Kuruli & Nighoje, Taluka : Khed, Pune – 410 501</p>
                    <p>Maharashtra- India</p>
                </div>
                <div className='customer_ser_sec'>
                    <p className='cnt_main_p two'>Customer Service Centre</p>
                    <p>Customer Service Centre: <a href="tel:1800 212 3374">1800 212 3374</a>  / <a href="tel:1800 212 2237">1800 212 2237</a></p>
                    <p> from 09:00 – 18:00 (Mon – Fri) & 09:00 – 13:00 (Sat), except</p>
                    <p>  public holidays.</p>
                </div>
                <p className='mail_to_txt'>Mail to us :<a href='mailto:mbfsindia@mercedes-benz.com'>mbfsindia@mercedes-benz.com</a></p>
                <p className=''>Request a Call-Back: ⁣<a href="https://www.mercedes-benz.co.in/passengercars/finance/finance-form.html">Finance Form(mercedes-benz.co.in)</a></p>
               
                <div className='notification'>
                    <p className='notification_imp'>To ensure you don't miss out on important updates, please keep your contact details up to date.</p>
                    <p>By maintaining accurate information, you'll receive:</p>
                    <ul className='notification_ul'>
                        <li>Notifications about any changes to our contact details.</li>
                        <li>Important regulatory updates.</li>
                        <li>Updates regarding changes to your loan contract.</li>
                        <li>Notification on EMI transactions.</li>
                        <li>Exclusive and customized benefits.</li>
                        <li>Update on latest offers.</li>
                    </ul>
                    <p>Your updated contact details help us keep you informed and ensure you receive the benefits you deserve.</p>
                    <p className='mail_to_txt_2'>To update your latest contact number or email ID please log in to MBFSI’s Customer  Self Service Portal <a href="https://mymbfs.com/"> www.mymbfs.com </a> or <br /> call on Toll Free Number <a href="tel:1800-212-3374"> 1800-212-3374</a>  or email us on <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a> </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default More_info