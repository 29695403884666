import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import Our_company_menu from '../Our_company_page/Our_company_menu'
import "./Leadership.css"


const Leadership = () => {

    var leader_img = require('./Leaderdhip_images/Brendon.jpg');
    var leader_img_1 = require('./Leaderdhip_images/Ingo_.jpg');
    var leader_img_2 = require('./Leaderdhip_images/Harish_.jpg');
    var leader_img_3 = require('./Leaderdhip_images/Kishendran_.jpg');
    var leader_img_4 = require('./Leaderdhip_images/Balwant_.jpg');
    var leader_img_5 = require('./Leaderdhip_images/Krithika_.jpg');
    var leader_img_6 = require('./Leaderdhip_images/Purshottam_.jpg');
    var leader_img_7 = require('./Leaderdhip_images/Gaurav_.jpg');
    var leader_img_8 = require('./Leaderdhip_images/Anita_.jpg');
    var leader_img_9 = require('./Leaderdhip_images/Gurindar_.jpg');



    return (
        <div>
            <Header />
            <Header_two />
            <Our_company_menu />
            <div className='container-fluid leader_ship_first_hed'>
                <div className='container'>
                    <h1 className='leadr_hed'>Mercedes-Benz Financial Services Leadership Team</h1>
                </div>
            </div>
            <div className='container'>
                <div className='top_head_lead_new mt-5 mb-5'>
                    <p>Here you will find all leadership team members of Mercedes-Benz Financial Services.</p>
                </div>

                <div className='row leader_row'>
                    <div className='col-12 col-lg-7'> <img src={leader_img} /></div>
                    <div className='col-12 col-lg-5 leader_name'><h1>Brendon Sissing</h1> <p>CEO & Managing Director, <br />Mercedes-Benz Financial Services.</p></div>
                </div>
                <div className='row leader_row mt-5 rev'>
                    <div className='col-12 col-lg-5 leader_name'><h1>Ingo Krueger</h1> <p>Head of Credit & Risk Management,<br />Mercedes-Benz Financial Services.</p></div>
                    <div className='col-12 col-lg-7 right_img'> <img src={leader_img_1} /></div>
                </div>


                <div className='row leader_row mt-5'>
                    <div className='col-12 col-lg-7'> <img src={leader_img_4} /></div>
                    <div className='col-12 col-lg-5 leader_name'><h1>Balwant Singh</h1> <p>Head of Sales & Marketing, <br />Mercedes-Benz Financial Services.</p></div>
                </div>
                <div className='row leader_row mt-5 rev'>
                  
                    <div className='col-12 col-lg-5 leader_name'><h1>Harish Chand Jain</h1> <p>Chief Financial Officer, <br />Mercedes-Benz Financial Services.</p></div>
                    <div className='col-12 col-lg-7 right_img'> <img src={leader_img_2} /></div>
                </div>

                <div className='row leader_row mt-5'>
                    <div className='col-12 col-lg-7'> <img src={leader_img_5} /></div>
                    <div className='col-12 col-lg-5 leader_name'><h1>Krithika Sivanesan</h1> <p>Head of Human Resources, <br />Mercedes-Benz Financial Services.</p></div>
                  
                </div>
                <div className='row leader_row mt-5 rev'>
                <div className='col-12 col-lg-5 leader_name'><h1>Kishendran Gunalan</h1> <p>Chief Information Officer,<br />Mercedes-Benz Financial Services.</p></div>
                   
                    <div className='col-12 col-lg-7 right_img'> <img src={leader_img_3} /></div>
                </div>

                <div className='row leader_row mt-5'>
                    <div className='col-12 col-lg-7'> <img src={leader_img_6} /></div>
                    <div className='col-12 col-lg-5 leader_name'><h1>Purshottam G</h1> <p>Head of Insurance, <br />Mercedes-Benz Financial Services.</p></div>
                </div>
                <div className='row leader_row mt-5 rev'>
                    <div className='col-12 col-lg-5 leader_name'><h1>Gaurav Sharma</h1> <p>Head of Retail, Corporate & Wholesale Credit, <br />Mercedes-Benz Financial Services.</p></div>
                    <div className='col-12 col-lg-7 right_img'> <img src={leader_img_7} /></div>
                </div>

                <div className='row leader_row mt-5'>
                    <div className='col-12 col-lg-7'> <img src={leader_img_9} /></div>
                    <div className='col-12 col-lg-5 leader_name'><h1>Gurinder Singh</h1> <p>Head of Operations & Collections,<br />Mercedes-Benz Financial Services.</p></div>
                </div>
                <div className='row leader_row mt-5 mb-5 pb-5 rev'>
                    <div className='col-12 col-lg-5 leader_name'><h1>Anita G Iyer</h1> <p>Head of Legal,<br />Mercedes-Benz Financial Services.</p></div>
                    <div className='col-12 col-lg-7 right_img'> <img src={leader_img_8} /></div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Leadership