// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("MB_Fonts/MBCorpoATextCond-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("MB_Fonts/MBCorpoSText-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("MB_Fonts/MBCorpoSText-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'MBcropa';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("OpenType");
    font-style: normal;
}
@font-face {
    font-family: 'MBcrop';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("OpenType");
    font-style: normal;
}
@font-face {
    font-family: 'MBcrop';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("OpenType");
    font-weight: 600;
}

h1,h2,h3{
    font-family:MBcropa !important;
}
p,h4,h5,h6,li{
    font-family:MBcrop !important;
}
`, "",{"version":3,"sources":["webpack://./src/Fontstyle.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,+DAAoE;IACpE,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,+DAA8D;IAC9D,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,+DAA6D;IAC7D,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;AAClC;AACA;IACI,6BAA6B;AACjC","sourcesContent":["@font-face {\n    font-family: 'MBcropa';\n    src: url('MB_Fonts/MBCorpoATextCond-Regular.otf') format(\"OpenType\");\n    font-style: normal;\n}\n@font-face {\n    font-family: 'MBcrop';\n    src: url('MB_Fonts/MBCorpoSText-Light.otf') format(\"OpenType\");\n    font-style: normal;\n}\n@font-face {\n    font-family: 'MBcrop';\n    src: url('MB_Fonts/MBCorpoSText-Bold.otf') format(\"OpenType\");\n    font-weight: 600;\n}\n\nh1,h2,h3{\n    font-family:MBcropa !important;\n}\np,h4,h5,h6,li{\n    font-family:MBcrop !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
