import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import "./For_info.css"
import More_ifo_menu from './More_ifo_menu'


const FAQ = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className='container pad_cnt_mob'>
                <h1 className='cnt_us_main_hed'>FAQs</h1>
                <div className='slect_vech'>
                    <ul className="nav nav-pills nav_pills_b" id="pills-tab" role="tablist">
                        <li className="nav-item nav_item" role="presentation">
                            <button className="nav-link nav_item_btn active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab">PC (Passenger Car)</button>
                        </li>
                        <li className="nav-item nav_item" role="presentation">
                            <button className="nav-link nav_item_btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab">CV (Commercial Vehicle)</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel">


                            <h3 className='cnt_us_main_hed'>PC (Passenger Car)</h3>


                            <div className="accordion" id="accordian_pc">
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_one">
                                        SOA/ Balance confirmation/Repayment Schedule
                                    </button>
                                    <div id="collapse_pc_one" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordian_pc" >
                                        <div className="accordion-body">


                                            <div className="accordion" id="accordian_pc_ins_1">
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_1_one">
                                                        How do I get my Statement of account ?
                                                    </button>
                                                    <div id="collapse_pc_in_1_one" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordian_pc_ins_1" >
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and Click on vehicle image. Select “Statement of account” tab. Choose the period for which SOA is required and click Download.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_1_two">
                                                        How do I get my Repayment schedule ?
                                                    </button>
                                                    <div id="collapse_pc_in_1_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_1">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and Click on vehicle image. Select “Repayment Plan” tab. Choose the period for which repayment schedule is required and click Download.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_1_three">
                                                        How do I get my Balance confirmation / Interest Certificate ?
                                                    </button>
                                                    <div id="collapse_pc_in_1_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_1">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and Click on “Payments” tab on left side of the window. Click on “View payment details” button. Scroll down and select “Balance confirmation” tab. Choose the period for which Balance confirmation is required and click Download.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_1_four">
                                                        When can I get the updated Statement of account after I make the payment ?
                                                    </button>
                                                    <div id="collapse_pc_in_1_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_1">
                                                        <div className="accordion-body">
                                                            <div className='panel-body'>
                                                                <p>Once you share the payment details, you can download the updated statement of account in 2 working days</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_two">
                                        EMI
                                    </button>
                                    <div id="collapse_pc_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_2">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_one">
                                                        What are the available EMI Date cycles ?
                                                    </button>
                                                    <div id="collapse_pc_in_2_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className='panel-body'>
                                                                <p>The Available EMI date is 4th,7th,13th or 18th of every month.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_two">
                                                        Can I change my EMI date?
                                                    </button>
                                                    <div id="collapse_pc_in_2_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className='panel-body'>
                                                                <p>Yes, you can change the EMI date as per above mentioned EMI cycle dates. The request needs to be sent from your registered email ID to <a href="mail_to:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com </a> / <a href="mail_to:bbfindia@mercedes-benz.com"></a>bbfindia@mercedes-benz.com.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_three">
                                                        What documents do I need to submit to process EMI date change ?
                                                    </button>
                                                    <div id="collapse_pc_in_2_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        If repayment mode is through ACH - PDC (Postdated cheques) for the next two EMIs, ACH forms (3 copies), interim interest charges (if applicable) through cheque or NEFT

                                                                    </li>
                                                                    <li>
                                                                        If repayment mode is through PDCs - PDC (Postdated cheques) for the remaining EMIs, Signature Verification form, interim interest charges (if applicable) through cheque or NEFT
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_four">
                                                        Are there any charges applicable for EMI date change ?
                                                    </button>
                                                    <div id="collapse_pc_in_2_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Interim interest will be charged from the old EMI date to new EMI date. The amount has to be settled through cheque/ NEFT.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_five">
                                                        What are the bank details of Mercedes-Benz Financial Services India Private Limited?
                                                    </button>
                                                    <div id="collapse_pc_in_2_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <div className="table_whole_dv">
                                                                    <div className="table_dv_inner_head">
                                                                        Bank details for RTGS / NEFT procedure - (Note: This does not support IMPS and should not be used for Cheque/DD deposit)
                                                                    </div>
                                                                    <table className="inner_table">
                                                                        <tbody>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Account Name
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    Mercedes-Benz Financial Services India Pvt Ltd
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Account no
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    MBFS &lt; Contract Number &gt;
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Bank Name
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    JP Morgan Chase Bank
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Branch
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    Paranur Branch
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    IFSC Code
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    CHAS0INBX04
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <h5 style={{ "-webkit-text-decoration": "underline", "text-decoration": "underline", "font-style": "italic" }}>In IFSC code, please read 0 as zero and I as Letter "I"</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_six">
                                                        Where do I share details of EMI or other Outstanding charges payment?
                                                    </button>
                                                    <div id="collapse_pc_in_2_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> E-mail: <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>&nbsp; with transaction Reference Number/UTR Number and reason for fund transfer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_2_seven">
                                                        Where do I  download my EMI receipts?
                                                    </button>
                                                    <div id="collapse_pc_in_2_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and Click on “Payments” tab on left side of the window. Click on “View payment details” button. Scroll down and select “Transaction history” tab. Choose the period for which receipts are required and click Download.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_three">
                                        (COB) Change of Bank
                                    </button>
                                    <div id="collapse_pc_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_3">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_one">
                                                        What is COB ?
                                                    </button>
                                                    <div id="collapse_pc_in_3_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>COB means Change of Bank.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_two">
                                                        Are there any COB Charges applicable ?
                                                    </button>
                                                    <div id="collapse_pc_in_3_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, you need to pay ₹1180/- ( Charges ₹1000 + G.S.T.18%)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_three">
                                                        What documents are required to process COB in ECS-ACH Mode?
                                                    </button>
                                                    <div id="collapse_pc_in_3_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request along with reason for COB from your registered email id to <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  and 3 months bank statement
                                                                    </li>
                                                                    <li>
                                                                        Post our confirmation, please share 2 post dated EMI cheques, ACH forms (3 copies), Undated Security post dated cheque and Bank Swap charge cheque of ₹1,180/-
                                                                    </li>
                                                                    <li>
                                                                        All cheques must to&nbsp;be issued favoring <b>“Mercedes-Benz Financial Services India Private Limited".</b>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_four">
                                                        What documents are required to process COB in Post Dated cheques mode ?
                                                    </button>
                                                    <div id="collapse_pc_in_3_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request along with reason for COB from your registered email id to <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  and 3 months bank statement of new account
                                                                    </li>
                                                                    <li>
                                                                        Post our confirmation, please share post dated EMI cheques for remaining EMIs, softcopy of signature verification form attested by the banker and Bank Swap charge cheque of ₹1,180/-
                                                                    </li>
                                                                    <li>
                                                                        All cheques must to&nbsp;be issued favoring <b>“Mercedes-Benz Financial Services India Private Limited".</b>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_five">
                                                        Where do I send COB documents?
                                                    </button>
                                                    <div id="collapse_pc_in_3_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Customer Service Team, Mercedes-Benz Financial Services India Private Limited, E-3, MIDC, Phase-III, Chakan Industrial Area, Kuruli & Nighoje, Taluka Khed, Pune, Maharashtra 410501.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_six">
                                                        In how many days the new bank account will get registered?
                                                    </button>
                                                    <div id="collapse_pc_in_3_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>It will take at least 45 days to register the new bank account.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_seven">
                                                        What details need to be filled in ECS-ACH forms?
                                                    </button>
                                                    <div id="collapse_pc_in_3_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                For more information, please contact the nearest dealer or contact us at: <br />
                                                                E-mail : <a href="mailto:mbfsindia@mercedebenz.com">mbfsindia@mercedes-benz.com</a>
                                                                Toll free number <a href="tel:1800-212-3374">1800-212-3374</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_eight">
                                                        What would happen to my old cheques?
                                                    </button>
                                                    <div id="collapse_pc_in_3_eight" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>If we receive a request from your registered email id, we will retrieve the old cheques and send it to your registered address in 30 working days.</li>
                                                                    <li>
                                                                        If no request is received, we destroy the cheques in 15 working days from the day of processing of COB request in our system
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_3_nine">
                                                        How do I get blank ACH forms?
                                                    </button>
                                                    <div id="collapse_pc_in_3_nine" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and go to “Support” section at the top right corner of the window and then “Forms” tab.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_four">
                                        Early termination / Forclousure
                                    </button>
                                    <div id="collapse_pc_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_4">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_one">
                                                        What is ET Quote ?
                                                    </button>
                                                    <div id="collapse_pc_in_4_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>ET quote is Early Termination quotation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_two">
                                                        When can I early terminate my loan?
                                                    </button>
                                                    <div id="collapse_pc_in_4_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Early termination of loan can be done post completion of at least 6 months period.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_three">
                                                        How do I early terminate my loan?
                                                    </button>
                                                    <div id="collapse_pc_in_4_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request for ET quote from your registered email id to <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>
                                                                    </li>
                                                                    <li>We will share the ET quote along with bank details to make the payment. Please note early termination quotation cannot be generated 6 days prior &amp; post EMI date.</li>
                                                                    <li>
                                                                        In case of Net-off TDS, Early termination quote would include TDS outstanding (if applicable). Share the transaction Reference Number/UTR Number
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_four">
                                                        Are there any early termination charges applicable ?
                                                    </button>
                                                    <div id="collapse_pc_in_4_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, 4% charges on principle outstanding on the date of request plus 18% GST will be applicable as per the loan agreement</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_five">
                                                        Can I make ET payment in parts?
                                                    </button>
                                                    <div id="collapse_pc_in_4_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Yes, ET payment can be made in parts but within 3 days (not working days) of ET quote validity date
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_six">
                                                        Can third party make ET payment on my behalf?
                                                    </button>
                                                    <div id="collapse_pc_in_4_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Yes. Please submit Third party declaration form along with KYCs of the third party. To download third party declaration form, kindly visit COS (Customer online service portal) at <a href="www.mymbfs.com" target="_blank">www.mymbfs.com</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_seven">
                                                        When would I receive the original NOC of early terminated loan?
                                                    </button>
                                                    <div id="collapse_pc_in_4_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Once the loan is early terminated in our system, we will share the soft copy of NOC on your registered email id within 7-8 working days and original NOC/Documents will be dispatched to your registered address within 15 working days from the date of closure.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_five">
                                        Part Principal Pre-Payment
                                    </button>
                                    <div id="collapse_pc_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_5">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_5_one">
                                                        Is there any part principal pre-payment charges applicable?
                                                    </button>
                                                    <div id="collapse_pc_in_5_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_5">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, 4% charges on part principal pre-payment on the date of request plus 18% GST will be applicable as per the loan agreement</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_5_two">
                                                        How do I make part principal pre-payment
                                                    </button>
                                                    <div id="collapse_pc_in_5_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_5">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request mentioning part principal pre-payment amount from your registered email id to <a href="maiilto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  Please specify clearly if the amount is inclusive or exclusive of part principal pre-payment charges. Also confirm if you wish to reduce EMI or tenure.

                                                                    </li>
                                                                    <li>
                                                                        We will share the Mock repayment schedule on your registered email id
                                                                    </li>
                                                                    <li>
                                                                        Confirm to proceed
                                                                    </li>
                                                                    <li>
                                                                        Share the payment details. We will execute the request and share revised repayment schedule for your reference
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_six">
                                        Start Agility EOT
                                    </button>
                                    <div id="collapse_pc_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_6">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_6_one">
                                                        What options are available at the end of tenure?
                                                    </button>
                                                    <div id="collapse_pc_in_6_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        You can pay the balloon EMI or refinance and retain the car

                                                                    </li>
                                                                    <li>
                                                                        You can surrender the existing car
                                                                    </li>
                                                                    <li>
                                                                        Upgrade to your next car.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_6_two">
                                                        Can I re-finance the balloon payment at the end of contract?
                                                    </button>
                                                    <div id="collapse_pc_in_6_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, Re-finance is offered at the sole discretion of MBFS.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_6_three">
                                                        What is the current rate of interest charged on re-finance?
                                                    </button>
                                                    <div id="collapse_pc_in_6_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Kindly reach out to nearest dealer for more details
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_6_four">
                                                        Is it possible to change the kilometer terms in between contract of STAR Agility?
                                                    </button>
                                                    <div id="collapse_pc_in_6_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>No, kilometer terms are decided at the start of contract and remains fixed throughout the term.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_6_five">
                                                        Where do I surrender the vehicle?
                                                    </button>
                                                    <div id="collapse_pc_in_6_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>The car should be handed over to the dealer from where you had taken the delivery.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_6_six">
                                                        When would I get refund of my security deposit?
                                                    </button>
                                                    <div id="collapse_pc_in_6_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Security deposit would be refunded within 7 working days from the date of closure. Any overdue towards the account would be adjusted on your confirmation before initiating refund. Please share alternate account details if existing account is closed or is NRE/NRO account.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_seven">
                                        NOC
                                    </button>
                                    <div id="collapse_pc_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_7">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_7_one">
                                                        What is (NOC) No objection certificate?
                                                    </button>
                                                    <div id="collapse_pc_in_7_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                No Objection certificate is issued on multiple scenarios, few of which are the following:
                                                                <table className="inner1_tbl">
                                                                    <tbody>
                                                                        <tr className="inner1_tbl_tr">
                                                                            <th >NOC</th>
                                                                            <th >
                                                                                Purpose
                                                                            </th>
                                                                            <th>Requisites</th>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                Hypothecation Removal
                                                                            </td>
                                                                            <td>
                                                                                To remove DFS Hypothecation from RC
                                                                            </td>
                                                                            <td>
                                                                                Nil Receivables (fully paid EMI’s and charges)
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt2">
                                                                            <td>
                                                                                Duplicate RC
                                                                            </td>
                                                                            <td>
                                                                                To avail duplicate RC from RTO
                                                                            </td>
                                                                            <td>
                                                                                FIR copy, Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                RTO change
                                                                            </td>
                                                                            <td>
                                                                                To change from one RTO to other RTO
                                                                            </td>
                                                                            <td>
                                                                                Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt2">
                                                                            <td>
                                                                                Change of registration number Board
                                                                            </td>
                                                                            <td>
                                                                                To change from white to yellow board and vice-versa
                                                                            </td>
                                                                            <td>
                                                                                Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                Change of address
                                                                            </td>
                                                                            <td>
                                                                                To change the address in RC
                                                                            </td>
                                                                            <td>
                                                                                Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_7_two">
                                                        How do I request for NOC?
                                                    </button>
                                                    <div id="collapse_pc_in_7_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Please write to us at <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  or call us on toll free number <a href="tel:1800-212-3374">1800-212-3374</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_7_three">
                                                        What is the validity of a NOC?
                                                    </button>
                                                    <div id="collapse_pc_in_7_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>NOC is valid for a period of three months.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_7_four">
                                                        How do I remove hypothecation from RC?
                                                    </button>
                                                    <div id="collapse_pc_in_7_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Submit NOC and other required documents to local RTO to remove hypothecation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_7_five">
                                                        Benefits of having NOC for removal of hypothecation?
                                                    </button>
                                                    <div id="collapse_pc_in_7_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>It is important to have a legal document which clearly states that you do not owe anything more to the lender. This way you are protected legally.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="accordion-item">
                                        <button class="accordion-button collapsed"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse_pc_in_7_six">
                                            How to get NOC from MBFSI, in case of death or borrower?
                                        </button>
                                        <div id="collapse_pc_in_7_six" class="accordion-collapse collapse"
                                             data-bs-parent="#accordian_pc_ins_7">
                                            <div class="accordion-body">
                                                <div class="panel-body">
                                                    <p>
                                                        Legal heir should write an email to customer service team <a href="mailto:mbfsindia@mercedes-benz.com">(mbfsindia@mercedes-benz.com)</a> with a following supporting documents.
                                                    </p>
                                                    <ul class="panel_list mt-2">
                                                        <li>Death Certificate</li>
                                                        <li>NOC Legal Heir Certificate ( Affidavit with notarization)</li>
                                                        <li>Succession Certificate ( if available)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_eight">
                                        COS Portal
                                    </button>
                                    <div id="collapse_pc_eight" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_8">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_one">
                                                        What is COS (Customer online service) portal?
                                                    </button>
                                                    <div id="collapse_pc_in_8_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>It is a self service customer online portal where you can view or download details related to your loan.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_two">
                                                        How do I register on COS (Customer online service) portal?
                                                    </button>
                                                    <div id="collapse_pc_in_8_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Visit customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>). Click on “Register Now”, enter PAN# and click Continue to receive OTP on your registered mobile number. Enter the OTP to set the password. You may now sign in by entering registered email id as username and newly set password.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_three">
                                                        What services are offered by (Customer online service) portal?
                                                    </button>
                                                    <div id="collapse_pc_in_8_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>You can download SOA, Repayment schedule, Balance confirmation, EMI receipts and Overdue details. You can also change your email id, mobile # and address.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_four">
                                                        How do I download SOA (Statement of accounts) and Balance confirmation?
                                                    </button>
                                                    <div id="collapse_pc_in_8_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>  Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and Click on vehicle image. Select “Statement of account” tab. Choose the period for which SOA is required and click Download. For balance confirmation, click on “Payments” tab on left side of the window. Click on “View payment details” button. Scroll down and select “Balance confirmation” tab. Choose the period for which Balance confirmation is required and click Download.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_five">
                                                        What is the process to update the address or Change of address ?
                                                    </button>
                                                    <div id="collapse_pc_in_8_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal ( www.mymbfs.com ) and click on “My Profile” tab on left side of the window. Scroll down to address section and click on “edit”. Update or change the address and upload supporting documents. For the list of OVDs (Officially valid documents) please go to “support” section at the top right corner of the window and then “Forms” tab. Please note that request would be rejected if supporting documents are invalid, illegible or mismatch between documents and the address updated on COS portal.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_six">
                                                        Is Aadhaar Mandatory to update \ Change address?
                                                    </button>
                                                    <div id="collapse_pc_in_8_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>No, Aadhaar is not Mandatory.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_seven">
                                                        I am Unable to update \ Change Address in COS Portal and in Website?
                                                    </button>
                                                    <div id="collapse_pc_in_8_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> You can write to us at <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  from your registered email id or call our toll free number <a href="tel:1800-212-3374">1800-212-3374</a>  from registered Mobile Number.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_eight">
                                                        How will I know if my Address has updated \ Changed in your record?
                                                    </button>
                                                    <div id="collapse_pc_in_8_eight" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a> ). Click on the Email icon on top left side of the window to view the confirmation email.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_nine">
                                                        My registered Mobile number is not in use now, how do I register my new mobile number?
                                                    </button>
                                                    <div id="collapse_pc_in_8_nine" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and click on “My Profile” tab on left side of the window. Scroll down to Preferred Mobile section and click on “edit”. Change the mobile#. You will receive an OTP, enter the OTP to confirm changes. Your mobile number is now updated.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_8_ten">
                                                        How do I update my Email id?
                                                    </button>
                                                    <div id="collapse_pc_in_8_ten" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal (<a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a>) and click on “My Profile” tab on left side of the window. Scroll down to Preferred Email section and click on “edit”. Change the email id. You will receive a verification email with link. Click on the link to confirm changes.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_nine">
                                        MBIL \ DICV - Product or Service related
                                    </button>
                                    <div id="collapse_pc_nine" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_9">


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_9_one">
                                                        Whom should I contact for Mercedes-Benz product and service related queries ?
                                                    </button>
                                                    <div id="collapse_pc_in_9_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_9">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Please contact <a href="mailto:cs.ind@cac.mercedes-benz.com">cs.ind@cac.mercedes-benz.com</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_9_two">
                                                        Whom should I contact for Bharat Benz product and service related queries?
                                                    </button>
                                                    <div id="collapse_pc_in_9_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_9">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>  Please contact <a href="mailto:dicv_service@daimler.com">dicv_service@daimler.com</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_ten">
                                        Loan Restructure
                                    </button>
                                    <div id="collapse_pc_ten" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_10">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_10_one">
                                                        What is loan Restructure?
                                                    </button>
                                                    <div id="collapse_pc_in_10_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Loan restructuring is a process in which borrowers facing financial distress renegotiate and modify the terms of the loan with the lender to avoid default subject to discretion of the lender.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_10_two">
                                                        How do I avail the restructuring benefit on my loan?
                                                    </button>
                                                    <div id="collapse_pc_in_10_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Customers shall write to <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a> to restructure the loan. The feasibility of restructuring a loan is under the discretion of MBFS</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_10_three">
                                                        What is the effect of loan restructure on credit score?
                                                    </button>
                                                    <div id="collapse_pc_in_10_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Loan is reported to the Bureau as “Restructured”, which will have an impact on the credit score.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_10_four">
                                                        If restructuring is approved by MBFS, what will be the initial procedure?
                                                    </button>
                                                    <div id="collapse_pc_in_10_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Outstanding charges to be settled by Customers
                                                                    </li>
                                                                    <li>
                                                                        Restructuring fee – INR 5000/- per loan is applicable
                                                                    </li>
                                                                    <li>
                                                                        Mock Repayment schedule will be shared, by increasing the Interest Rate up to 2.5%
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_10_five">
                                                        Will all stakeholders of the loan (Borrower/ Co-borrower/ Guarantor) will have their credit score affected?
                                                    </button>
                                                    <div id="collapse_pc_in_10_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, the credit score will be impacted for all stakeholders in the loan</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_leven">
                                        Contract Changes
                                    </button>
                                    <div id="collapse_pc_leven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_11">
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_11_one">
                                                        What are Contract Changes?
                                                    </button>
                                                    <div id="collapse_pc_in_11_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_11">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Any changes in the constitution of the Borrower/ Co-borrower/ Guarantor of the loan</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_11_two">
                                                        What are the scenarios for contract changes?
                                                    </button>
                                                    <div id="collapse_pc_in_11_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_11">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Death of Borrower, Change in constitution, Mergers and Acquisitions</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_11_three">
                                                        What all documents to be submitted for contract changes?
                                                    </button>
                                                    <div id="collapse_pc_in_11_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_11">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>This depends on the type of scenario. Kindly write to <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a> and we will share the supporting documents required to process your request.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_twele">
                                        CIBIL
                                    </button>
                                    <div id="collapse_pc_twele" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_12">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_12_one">
                                                        What is meant by CIBIL?
                                                    </button>
                                                    <div id="collapse_pc_in_12_one" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>CIBIL (Credit Information Bureau (India) Limited) is a Credit Bureau or Credit Information Company. This company is engaged in maintaining the records of all the credit-related activities of companies as well as individuals including credit cards and loans</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_12_two">
                                                        How do I know my Cibil score?
                                                    </button>
                                                    <div id="collapse_pc_in_12_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log on to the CIBIL website. Enter personal details such as name, PAN card number, date of birth, gender, etc. clear the personal verification process, and make a fee payment in order to access your credit report.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_12_three">
                                                        Whom do I approach for any dispute related to Cibil reporting?
                                                    </button>
                                                    <div id="collapse_pc_in_12_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        You may write to us at <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a> from your registered email id with your loan account number and latest Cibil report.

                                                                    </li>
                                                                    <li>
                                                                        You may call us at <a href="tel:1800-212-3374">1800-212-3374</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_12_four">
                                                        Do Customers with Net Off TDS EMI loan reported in Cibil?
                                                    </button>
                                                    <div id="collapse_pc_in_12_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Customer with Net Off TDS EMI is reported in Cibil if total TDS outstanding is more than 1 EMI.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_12_five">
                                                        How many days does it take to rectify in CIBIL due to incorrect reporting?
                                                    </button>
                                                    <div id="collapse_pc_in_12_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Correction of individual CIBIL takes 7 working days and commercial CIBIL takes 25 working days.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_12_six">
                                                        What action is expected from my side if EMI is net off TDS ?
                                                    </button>
                                                    <div id="collapse_pc_in_12_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        File TDS return basis repayment schedule, quarterly
                                                                    </li>
                                                                    <li>
                                                                        For more information, you may write to us at <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_thirten">
                                        TDS Related
                                    </button>
                                    <div id="collapse_pc_thirten" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_pc_ins_13">


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_one_new">
                                                        What is TDS and how is it related to my loan account?
                                                    </button>
                                                    <div id="collapse_pc_in_13_one_new" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_13">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>TDS is Tax Deducted at Source. As Mercedes-Benz Financial Services India Private Limited (MBFS) is a Non-Banking Financial Company (NBFC), interest remitted by a class of persons (as specified by the Income Tax Act, 1961 (hereinafter referred to as ‘Act’)) is subject to tax deduction at source (TDS) on a timely basis.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_two_new">
                                                        What is Gross Mode and Net off Mode?
                                                    </button>
                                                    <div id="collapse_pc_in_13_two_new" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_13">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <div className="panel-body">
                                                                    <p className='center_tg_divd'>Every loan contract is classified as either “Net TDS Mode” or “Gross TDS mode” contract:</p>
                                                                    <ul className="panel_list">
                                                                        <li>
                                                                            Net TDS Mode -
                                                                        </li>
                                                                        <p className='center_tg_divd'>In this mode, the EMI collected is net of TDS (i.e., EMI less TDS on the Interest component of EMI) and the TDS portion is receipted against the contract outstanding once the credit is available in our Form 26AS / Annual Tax Statement.</p>
                                                                        <li>
                                                                            Gross TDS Mode –
                                                                        </li>
                                                                        <p>In this mode, the EMI collected is including the TDS portion. Additionally, the customer shall remit at source the tax on interest component with the Income Tax authorities, Government of India. The remitted tax portion will be refunded to the customer once the credit is available in our Form 26AS / Annual Tax Statement.</p>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_three_new">
                                                        What is TDS Pro Software?
                                                    </button>
                                                    <div id="collapse_pc_in_13_three_new" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_13">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>In our endeavor to pleasantly exceed your expectations, we have designed a tool “TDS Pro”. This tool assists the customers to manage their tax obligations for all loan contracts.</li>
                                                                    <li>URL: <a target='_blank' href="https://www.update.mercedes-benz-financialservices.in/TDSPRO">https://www.update.mercedes-benz-financialservices.in/TDSPRO</a></li>
                                                                    <li>Username: PAN Number</li>
                                                                    <li>Password: For First time user – Click on Forgot Password and enter the PAN number to receive the password on registered email ID.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_four_new">
                                                        What are all features available in TDS Pro tool?
                                                    </button>
                                                    <div id="collapse_pc_in_13_four_new" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_13">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p className='panel_by_head'>Here are some of salient features of the TDS Pro tool:</p>
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        The tool can be accessed using the below link:
                                                                    </li>
                                                                    <li>
                                                                        Customers can link their Tax deduction Account Number (TAN) (by uploading relevant proof) against their PAN available in our records, to have a transparent obligation / requirement on TDS.
                                                                    </li>
                                                                    <li>
                                                                        Customers can view their tax obligation over the life cycle of loan contract.
                                                                    </li>
                                                                    <li>
                                                                        Customers can view and track the live position of the TDS that has been remitted in Form 26AS / Annual Tax Statement.
                                                                    </li>
                                                                    <li>
                                                                        Customers opting for Gross TDS Mode Contracts, can claim their refunds in a simple and efficient way.
                                                                    </li>
                                                                    <li>
                                                                        For customers opting for Net TDS Mode Contracts, once the credit is available in Form 26AS as per the ‘expected TDS’, the loan outstanding dues will be accounted smoothly with the help of the Tool.
                                                                    </li>
                                                                    <li>
                                                                        In case of any mismatch between ‘expected TDS’ and ‘actual TDS remittance’ (appearing in Form 26AS), customers can provide breakup for the TDS remitted.
                                                                    </li>
                                                                    <li className='last_p_ul'>
                                                                        In case of multiple TANs, customer has an option to assign the TANs in the Tool.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_five_new">
                                                        Incase of any issue, whom to contact?
                                                    </button>
                                                    <div id="collapse_pc_in_13_five_new" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_ins_13">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Please feel free to call our toll free number <a href="tel:1800-212-3374"> 1800 212 3374 </a> or email us at  <a href="mbfsindia@mercedes-benz.com"> mbfsindia@mercedes-benz.com </a> with relevant issue details.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_fourteen">
                                        EMI Mode Change
                                    </button>
                                    <div id="collapse_pc_fourteen" className="accordion-collapse collapse" data-bs-parent="#accordian_pc">
                                        <div className="accordion-body">
                                            <div className="panel-body">
                                                <p>Once the contract is activated, changing the EMI from Gross to Netoff or Netoff to Gross is not feasible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel">

                            <h3 className='cnt_us_main_hed'>CV (Commercial Vehicle)</h3>

                            <div className="accordion" id="accordian_pc_2">
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_one">
                                        SOA/ Balance confirmation/Repayment Schedule
                                    </button>
                                    <div id="collapse_pc_2_one" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordian_pc_2" >
                                        <div className="accordion-body">


                                            <div className="accordion" id="accordian_cv_ins_1">
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_1_one">
                                                        How do I get my Statement of account ?
                                                    </button>
                                                    <div id="collapse_cv_in_1_one" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordian_cv_ins_1" >
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>
                                                                ) and Click on “Statement of account” tab on the left side of the screen. Choose the period for which SOA is required and click Export PDF.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_1_two">
                                                        How do I get my Repayment schedule ?
                                                    </button>
                                                    <div id="collapse_cv_in_1_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_1">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>
                                                                ) and Click on “Repayment Schedule” tab and click on Export PDF.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_1_three">
                                                        How do I get my Balance confirmation / Interest Certificate ?
                                                    </button>
                                                    <div id="collapse_cv_in_1_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_1">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and Click on “Balance confirmation” tab on the left side of the screen. Choose the period for which Balance confirmation is required and click Export PDF.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_1_four">
                                                        When can I get the updated Statement of account after I make the payment ?
                                                    </button>
                                                    <div id="collapse_cv_in_1_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_1">
                                                        <div className="accordion-body">
                                                            <div className='panel-body'>
                                                                <p>Once you share the payment details, you can download the updated statement of account in 2 working days</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_two">
                                        EMI
                                    </button>
                                    <div id="collapse_pc_2_two" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_2">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_2_one">
                                                        What are the available EMI Date cycles ?
                                                    </button>
                                                    <div id="collapse_cv_in_2_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_2">
                                                        <div className="accordion-body">
                                                            <div className='panel-body'>
                                                                <p>The Available EMI date is 4th,7th,13th or 18th of every month.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_2_two">
                                                        Can I change my EMI date?
                                                    </button>
                                                    <div id="collapse_cv_in_2_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_2">
                                                        <div className="accordion-body">
                                                            <div className='panel-body'>
                                                                <p>Yes, you can change the EMI date as per above mentioned EMI cycle dates. The request needs to be sent from your registered email ID to <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_2_three">
                                                        What documents do I need to submit to process EMI date change ?
                                                    </button>
                                                    <div id="collapse_cv_in_2_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        If repayment mode is through ACH - PDC (Postdated cheques) for the next two EMIs, ACH forms (3 copies), interim interest charges (if applicable) through cheque or NEFT

                                                                    </li>
                                                                    <li>
                                                                        If repayment mode is through PDCs - PDC (Postdated cheques) for the remaining EMIs, Signature Verification form, interim interest charges (if applicable) through cheque or NEFT
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_2_four">
                                                        Are there any charges applicable for EMI date change ?
                                                    </button>
                                                    <div id="collapse_cv_in_2_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Interim interest will be charged from the old EMI date to new EMI date. The amount has to be settled through cheque/ NEFT.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_2_five">
                                                        What are the bank details of Mercedes-Benz Financial Services India Private Limited?
                                                    </button>
                                                    <div id="collapse_cv_in_2_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <div className="table_whole_dv">
                                                                    <div className="table_dv_inner_head">
                                                                        Bank details for RTGS / NEFT procedure - (Note: This does not support IMPS and should not be used for Cheque/DD deposit)
                                                                    </div>
                                                                    <table className="inner_table">
                                                                        <tbody>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Account Name
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    Mercedes-Benz Financial Services India Pvt Ltd
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Account no
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    MBFS &lt; Contract Number &gt;
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Bank Name
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    JP Morgan Chase Bank
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    Branch
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    Paranur Branch
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="inner_tbl_row">
                                                                                <td className="inner_tbl_td1">
                                                                                    IFSC Code
                                                                                </td>
                                                                                <td className="inner_tbl_td2">
                                                                                    CHAS0INBX04
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <h5 style={{ "-webkit-text-decoration": "underline", "text-decoration": "underline", "font-style": "italic" }}>In IFSC code, please read 0 as zero and I as Letter "I"</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_2_six">
                                                        Where do I share details of EMI or other Outstanding charges payment?
                                                    </button>
                                                    <div id="collapse_cv_in_2_six" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_2">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> E-mail: <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>&nbsp; with transaction Reference Number/UTR Number and reason for fund transfer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_3_three">
                                        (COB) Change of Bank
                                    </button>
                                    <div id="collapse_pc_3_three" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_3">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_one">
                                                        What is COB ?
                                                    </button>
                                                    <div id="collapse_cv_in_3_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>COB means Change of Bank.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_two">
                                                        Are there any COB Charges applicable ?
                                                    </button>
                                                    <div id="collapse_cv_in_3_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, you need to pay swap charges of ₹1180/- ( Charges ₹1000 + G.S.T.18%)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_three">
                                                        What documents are required to process COB in ECS-ACH Mode?
                                                    </button>
                                                    <div id="collapse_cv_in_3_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request along with reason for COB from your registered email id to <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a> and 3 months bank statement
                                                                    </li>
                                                                    <li>
                                                                        Post our confirmation, please share 2 post dated EMI cheques, ACH forms (3 copies), Undated Security post dated cheque and Bank Swap charge cheque of ₹1,180/-
                                                                    </li>
                                                                    <li>
                                                                        All cheques must to&nbsp;be issued favoring <b>“Mercedes-Benz Financial Services India Private Limited".</b>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_four">
                                                        What documents are required to process COB in Post Dated cheques mode ?
                                                    </button>
                                                    <div id="collapse_cv_in_3_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request along with reason for COB from your registered email id to <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  and 3 months bank statement of new account
                                                                    </li>
                                                                    <li>
                                                                        Post our confirmation, please share post dated EMI cheques for remaining EMIs, softcopy of signature verification form attested by the banker and Bank Swap charge cheque of ₹1,180/-
                                                                    </li>
                                                                    <li>
                                                                        All cheques must to&nbsp;be issued favoring <b>“Mercedes-Benz Financial Services India Private Limited".</b>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_five">
                                                        Where do I send COB documents?
                                                    </button>
                                                    <div id="collapse_cv_in_3_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Customer Service Team, Mercedes-Benz Financial Services India Private Limited, E-3, MIDC, Phase-III, Chakan Industrial Area, Kuruli & Nighoje, Taluka Khed, Pune, Maharashtra 410501.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_six">
                                                        In how many days the new bank account will get registered?
                                                    </button>
                                                    <div id="collapse_cv_in_3_six" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>It will take at least 45 days to register the new bank account.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_seven">
                                                        What details need to be filled in ECS-ACH forms?
                                                    </button>
                                                    <div id="collapse_cv_in_3_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                For more information, please contact the nearest dealer or contact us at: <br />
                                                                E-mail : <a href="mailto:mbfsindia@mercedebenz.com">mbfsindia@mercedes-benz.com</a>
                                                                Toll free number <a href="tel:1800-212-3374">1800-212-3374</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_eight">
                                                        What would happen to my old cheques?
                                                    </button>
                                                    <div id="collapse_cv_in_3_eight" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>If we receive a request from your registered email id, we will retrieve the old cheques and send it to your registered address in 30 working days.</li>
                                                                    <li>
                                                                        If no request is received, we destroy the cheques in 15 working days from the day of processing of COB request in our system
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_3_nine">
                                                        How do I get blank ACH forms?
                                                    </button>
                                                    <div id="collapse_cv_in_3_nine" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_3">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and go to “Download” section and scroll down for details.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_four">
                                        Early termination / Forclousure
                                    </button>
                                    <div id="collapse_pc_2_four" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_4">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_4_one">
                                                        What is ET Quote ?
                                                    </button>
                                                    <div id="collapse_cv_in_4_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>ET quote is Early Termination quotation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_4_two">
                                                        When can I early terminate my loan?
                                                    </button>
                                                    <div id="collapse_cv_in_4_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Early termination of loan can be done post completion of at least 6 months period.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_4_three">
                                                        How do I early terminate my loan?
                                                    </button>
                                                    <div id="collapse_cv_in_4_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request for ET quote from your registered email id to <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a>
                                                                    </li>
                                                                    <li>We will share the ET quote along with bank details to make the payment. Please note early termination quotation cannot be generated 6 days prior &amp; post EMI date.</li>
                                                                    <li>
                                                                        In case of Net-off TDS, Early termination quote would include TDS outstanding (if applicable). Share the transaction Reference Number/UTR Number
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_4_four">
                                                        Are there any early termination charges applicable ?
                                                    </button>
                                                    <div id="collapse_cv_in_4_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, 4% charges on principle outstanding on the date of request plus 18% GST will be applicable as per the loan agreement</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_4_five">
                                                        Can I make ET payment in parts?
                                                    </button>
                                                    <div id="collapse_pc_in_4_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Yes, ET payment can be made in parts but within 3 days (not working days) of ET quote validity date
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_4_six">
                                                        Can third party make ET payment on my behalf?
                                                    </button>
                                                    <div id="collapse_cv_in_4_six" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Yes. Please submit Third party declaration form along with KYCs of the third party. To download third party declaration form, kindly visit COS (Customer online service portal) at <a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_4_seven">
                                                        When would I receive the original NOC of early terminated loan?
                                                    </button>
                                                    <div id="collapse_cv_in_4_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_4">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Once the loan is early terminated in our system, we will share the soft copy of NOC on your registered email id within 7-8 working days and original NOC/Documents will be dispatched to your registered address within 15 working days from the date of closure.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_five">
                                        Part Principal Pre-Payment
                                    </button>
                                    <div id="collapse_pc_2_five" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_5">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_5_one">
                                                        Is there any part principal pre-payment charges applicable?
                                                    </button>
                                                    <div id="collapse_cv_in_5_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_5">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, 4% charges on part principal pre-payment on the date of request plus 18% GST will be applicable as per the loan agreement</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_5_two">
                                                        How do I make part principal pre-payment
                                                    </button>
                                                    <div id="collapse_cv_in_5_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_5">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Send the request mentioning part principal pre-payment amount from your registered email id to <a href="maiilto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a>  Please specify clearly if the amount is inclusive or exclusive of part principal pre-payment charges. Also confirm if you wish to reduce EMI or tenure.

                                                                    </li>
                                                                    <li>
                                                                        We will share the Mock repayment schedule on your registered email id
                                                                    </li>
                                                                    <li>
                                                                        Confirm to proceed
                                                                    </li>
                                                                    <li>
                                                                        Share the payment details. We will execute the request and share revised repayment schedule for your reference
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_six">
                                        Start Agility EOT
                                    </button>
                                    <div id="collapse_pc_2_six" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_6">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_6_one">
                                                        What options are available at the end of tenure?
                                                    </button>
                                                    <div id="collapse_cv_in_6_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        You can pay the balloon EMI or refinance and retain the car

                                                                    </li>
                                                                    <li>
                                                                        You can surrender the existing car
                                                                    </li>
                                                                    <li>
                                                                        Upgrade to your next car.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_6_two">
                                                        Can I re-finance the balloon payment at the end of contract?
                                                    </button>
                                                    <div id="collapse_cv_in_6_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, Re-finance is offered at the sole discretion of MBFS.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_6_three">
                                                        What is the current rate of interest charged on re-finance?
                                                    </button>
                                                    <div id="collapse_cv_in_6_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                Kindly reach out to nearest dealer for more details
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_6_four">
                                                        Is it possible to change the kilometer terms in between contract of STAR Agility?
                                                    </button>
                                                    <div id="collapse_cv_in_6_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>No, kilometer terms are decided at the start of contract and remains fixed throughout the term.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_6_five">
                                                        Where do I surrender the vehicle?
                                                    </button>
                                                    <div id="collapse_cv_in_6_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>The car should be handed over to the dealer from where you had taken the delivery.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_6_six">
                                                        When would I get refund of my security deposit?
                                                    </button>
                                                    <div id="collapse_cv_in_6_six" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_6">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Security deposit would be refunded within 7 working days from the date of closure. Any overdue towards the account would be adjusted on your confirmation before initiating refund. Please share alternate account details if existing account is closed or is NRE/NRO account.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_seven">
                                        NOC
                                    </button>
                                    <div id="collapse_pc_2_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_7">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_7_one">
                                                        What is (NOC) No objection certificate?
                                                    </button>
                                                    <div id="collapse_cv_in_7_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                No Objection certificate is issued on multiple scenarios, few of which are the following:
                                                                <table className="inner1_tbl">
                                                                    <tbody>
                                                                        <tr className="inner1_tbl_tr">
                                                                            <th >NOC</th>
                                                                            <th >
                                                                                Purpose
                                                                            </th>
                                                                            <th>Requisites</th>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                Hypothecation Removal
                                                                            </td>
                                                                            <td>
                                                                                To remove DFS Hypothecation from RC
                                                                            </td>
                                                                            <td>
                                                                                Nil Receivables (fully paid EMI’s and charges)
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt2">
                                                                            <td>
                                                                                Duplicate RC
                                                                            </td>
                                                                            <td>
                                                                                To avail duplicate RC from RTO
                                                                            </td>
                                                                            <td>
                                                                                FIR copy, Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                RTO change
                                                                            </td>
                                                                            <td>
                                                                                To change from one RTO to other RTO
                                                                            </td>
                                                                            <td>
                                                                                Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt2">
                                                                            <td>
                                                                                Change of registration number Board
                                                                            </td>
                                                                            <td>
                                                                                To change from white to yellow board and vice-versa
                                                                            </td>
                                                                            <td>
                                                                                Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                Change of address
                                                                            </td>
                                                                            <td>
                                                                                To change the address in RC
                                                                            </td>
                                                                            <td>
                                                                                Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                Change of Permit
                                                                            </td>
                                                                            <td>
                                                                                To change the permit from State to National and Vice-versa
                                                                            </td>
                                                                            <td>
                                                                                Existing Permit copy, Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="inner_tbl_tr_alt1">
                                                                            <td>
                                                                                Renewal of Permit
                                                                            </td>
                                                                            <td>
                                                                                To renew the permit validity
                                                                            </td>
                                                                            <td>
                                                                                Existing/ Old Permit copy, Nil outstanding charges
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_7_two">
                                                        How do I request for NOC?
                                                    </button>
                                                    <div id="collapse_cv_in_7_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Please write to us at <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a> or call us on toll free number <a href="tel:1800-212-2237">1800-212-2237</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_7_three">
                                                        What is the validity of a NOC?
                                                    </button>
                                                    <div id="collapse_cv_in_7_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>NOC is valid for a period of three months.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_7_four">
                                                        How do I remove hypothecation from RC?
                                                    </button>
                                                    <div id="collapse_cv_in_7_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Submit NOC and other required documents to local RTO to remove hypothecation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_7_five">
                                                        Benefits of having NOC for removal of hypothecation?
                                                    </button>
                                                    <div id="collapse_cv_in_7_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_7">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>It is important to have a legal document which clearly states that you do not owe anything more to the lender. This way you are protected legally.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="accordion-item">
                                        <button class="accordion-button collapsed"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapse_cv_in_7_six">
                                            How to get NOC from MBFSI, in case of death or borrower?
                                        </button>
                                        <div id="collapse_cv_in_7_six" class="accordion-collapse collapse"
                                             data-bs-parent="#accordian_pc_ins_7">
                                            <div class="accordion-body">
                                                <div class="panel-body">
                                                    <p>
                                                        Legal heir should write an email to customer service team <a href="mailto:mbfsindia@mercedes-benz.com">(mbfsindia@mercedes-benz.com)</a> with a following supporting documents.
                                                    </p>
                                                    <ul class="panel_list mt-2">
                                                        <li>Death Certificate</li>
                                                        <li>NOC Legal Heir Certificate ( Affidavit with notarization)</li>
                                                        <li>Succession Certificate ( if available)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_eight">
                                        COS Portal
                                    </button>
                                    <div id="collapse_pc_2_eight" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_8">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_one">
                                                        What is COS (Customer online service) portal?
                                                    </button>
                                                    <div id="collapse_cv_in_8_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>It is a self service customer online portal where you can view or download details related to your loan.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_two">
                                                        How do I register on COS (Customer online service) portal?
                                                    </button>
                                                    <div id="collapse_cv_in_8_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Visit customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>). Click on “Register Now”, enter PAN# and click Continue to receive OTP on your registered mobile number. Enter the OTP to set the password. You may now sign in by entering registered email id as username and newly set password.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_three">
                                                        What services are offered by (Customer online service) portal?
                                                    </button>
                                                    <div id="collapse_cv_in_8_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>You can download SOA, Repayment schedule and Balance confirmation. You can also raise a query to us through the portal.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_four">
                                                        How do I download SOA (Statement of accounts) and Balance confirmation?
                                                    </button>
                                                    <div id="collapse_cv_in_8_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>  Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and Click on “Statement of account” tab on the left side of the screen. Choose the period for which SOA is required and click Export PDF.
                                                                    For Balance confirmation, log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and Click on “Balance confirmation” tab on the left side of the screen. Choose the period for which Balance confirmation is required and click Export PDF.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_five">
                                                        What is the process to update the address or Change of address ?
                                                    </button>
                                                    <div id="collapse_cv_in_8_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and click on “My Profile” tab on left side of the window. Scroll down to address section and click on “edit”. Update or change the address and upload supporting documents. For the list of OVDs (Officially valid documents) please go to “support” section at the top right corner of the window and then “Forms” tab. Please note that request would be rejected if supporting documents are invalid, illegible or mismatch between documents and the address updated on COS portal.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_six">
                                                        Is Aadhaar Mandatory to update \ Change address?
                                                    </button>
                                                    <div id="collapse_cv_in_8_six" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>No, Aadhaar is not Mandatory.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_seven">
                                                        I am Unable to update \ Change Address in COS Portal and in Website?
                                                    </button>
                                                    <div id="collapse_cv_in_8_seven" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> You can write to us at <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a>  from your registered email id or call our toll free number <a href="tel:1800-212-2237">1800-212-2237</a> from registered Mobile Number.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_eight">
                                                        How will I know if my Address has updated \ Changed in your record?
                                                    </button>
                                                    <div id="collapse_cv_in_8_eight" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>  Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>). Click on the “Messages” icon on the top right side of the window. Once the screen loads, please view the confirmation email.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_nine">
                                                        My registered Mobile number is not in use now, how do I register my new mobile number?
                                                    </button>
                                                    <div id="collapse_cv_in_8_nine" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>  Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and click on “MyProfile” tab. Click on “Update Phone No.” on the left side of the screen. Update/ change the mobile number.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_8_ten">
                                                        How do I update my Email id?
                                                    </button>
                                                    <div id="collapse_cv_in_8_ten" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_8">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Log in to customer online service portal (<a href="https://www.mybbfs.co.in/" target="_blank">www.mybbfs.co.in</a>) and click on “MyProfile” tab. Click on “Update Email” on the left side of the screen. Update/ change the email id.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_nine">
                                        MBIL \ DICV - Product or Service related
                                    </button>
                                    <div id="collapse_pc_2_nine" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_9">


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_9_one">
                                                        Whom should I contact for Mercedes-Benz product and service related queries ?
                                                    </button>
                                                    <div id="collapse_cv_in_9_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_9">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p> Please contact <a href="mailto:cs.ind@cac.mercedes-benz.com">cs.ind@cac.mercedes-benz.com</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_9_two">
                                                        Whom should I contact for Bharat Benz product and service related queries?
                                                    </button>
                                                    <div id="collapse_cv_in_9_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_9">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>  Please contact <a href="mailto:dicv_service@daimler.com">dicv_service@daimler.com</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_ten">
                                        Loan Restructure
                                    </button>
                                    <div id="collapse_pc_2_ten" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_10">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_10_one">
                                                        What is loan Restructure?
                                                    </button>
                                                    <div id="collapse_cv_in_10_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Loan restructuring is a process in which borrowers facing financial distress renegotiate and modify the terms of the loan with the lender to avoid default subject to discretion of the lender.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_10_two">
                                                        How do I avail the restructuring benefit on my loan?
                                                    </button>
                                                    <div id="collapse_cv_in_10_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Customers shall write to <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a> to restructure the loan. The feasibility of restructuring a loan is under the discretion of MBFS</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_10_three">
                                                        What is the effect of loan restructure on credit score?
                                                    </button>
                                                    <div id="collapse_cv_in_10_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Loan is reported to the Bureau as “Restructured”, which will have an impact on the credit score.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_10_four">
                                                        If restructuring is approved by MBFS, what will be the initial procedure?
                                                    </button>
                                                    <div id="collapse_cv_in_10_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        Outstanding charges to be settled by Customers
                                                                    </li>
                                                                    <li>
                                                                        Restructuring fee – INR 5000/- per loan is applicable
                                                                    </li>
                                                                    <li>
                                                                        Mock Repayment schedule will be shared, by increasing the Interest Rate up to 2.5%
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_10_five">
                                                        Will all stakeholders of the loan (Borrower/ Co-borrower/ Guarantor) will have their credit score affected?
                                                    </button>
                                                    <div id="collapse_cv_in_10_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_10">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Yes, the credit score will be impacted for all stakeholders in the loan</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_leven">
                                        Contract Changes
                                    </button>
                                    <div id="collapse_pc_2_leven" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_11">
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_11_one">
                                                        What are Contract Changes?
                                                    </button>
                                                    <div id="collapse_cv_in_11_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_11">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Any changes in the constitution of the Borrower/ Co-borrower/ Guarantor of the loan</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_11_two">
                                                        What are the scenarios for contract changes?
                                                    </button>
                                                    <div id="collapse_cv_in_11_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_11">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Death of Borrower, Change in constitution, Mergers and Acquisitions</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_11_three">
                                                        What all documents to be submitted for contract changes?
                                                    </button>
                                                    <div id="collapse_cv_in_11_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_11">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>This depends on the type of scenario. Kindly write to <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a> and we will share the supporting documents required to process your request.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_twele">
                                        CIBIL
                                    </button>
                                    <div id="collapse_pc_2_twele" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_12">

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_12_one">
                                                        What is meant by CIBIL?
                                                    </button>
                                                    <div id="collapse_cv_in_12_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>CIBIL (Credit Information Bureau (India) Limited) is a Credit Bureau or Credit Information Company. This company is engaged in maintaining the records of all the credit-related activities of companies as well as individuals including credit cards and loans</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_12_two">
                                                        How do I know my Cibil score?
                                                    </button>
                                                    <div id="collapse_cv_in_12_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Log on to the CIBIL website. Enter personal details such as name, PAN card number, date of birth, gender, etc. clear the personal verification process, and make a fee payment in order to access your credit report.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_12_three">
                                                        Whom do I approach for any dispute related to Cibil reporting?
                                                    </button>
                                                    <div id="collapse_cv_in_12_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        You may write to us at <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a> from your registered email id with your loan account number and latest Cibil report.

                                                                    </li>
                                                                    <li>
                                                                        You may call us at <a href="tel:1800-212-2237">1800-212-2237</a>

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_12_four">
                                                        Do Customers with Net Off TDS EMI loan reported in Cibil?
                                                    </button>
                                                    <div id="collapse_cv_in_12_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Customer with Net Off TDS EMI is reported in Cibil if total TDS outstanding is more than 1 EMI.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_12_five">
                                                        How many days does it take to rectify in CIBIL due to incorrect reporting?
                                                    </button>
                                                    <div id="collapse_cv_in_12_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Correction of individual CIBIL takes 7 working days and commercial CIBIL takes 25 working days.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_cv_in_12_six">
                                                        What action is expected from my side if EMI is net off TDS ?
                                                    </button>
                                                    <div id="collapse_cv_in_12_six" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_12">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        File TDS return basis repayment schedule, quarterly
                                                                    </li>
                                                                    <li>
                                                                        For more information, you may write to us at <a href="mailto:bbfindia@mercedes-benz.com">bbfindia@mercedes-benz.com</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_thirten">
                                        TDS Related
                                    </button>
                                    <div id="collapse_pc_2_thirten" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">

                                            <div className="accordion" id="accordian_cv_ins_13_nw">


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_one">
                                                        What is TDS and how is it related to my loan account?
                                                    </button>
                                                    <div id="collapse_pc_in_13_one" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_13_nw">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>TDS is Tax Deducted at Source. As Mercedes-Benz Financial Services India Private Limited (MBFS) is a Non-Banking Financial Company (NBFC), interest remitted by a class of persons (as specified by the Income Tax Act, 1961 (hereinafter referred to as ‘Act’)) is subject to tax deduction at source (TDS) on a timely basis.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_two">
                                                        What is Gross Mode and Net off Mode?
                                                    </button>
                                                    <div id="collapse_pc_in_13_two" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_13_nw">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <div className="panel-body">
                                                                    <p className='center_tg_divd'>Every loan contract is classified as either “Net TDS Mode” or “Gross TDS mode” contract:</p>
                                                                    <ul className="panel_list">
                                                                        <li>
                                                                            Net TDS Mode -
                                                                        </li>
                                                                        <p className='center_tg_divd'>In this mode, the EMI collected is net of TDS (i.e., EMI less TDS on the Interest component of EMI) and the TDS portion is receipted against the contract outstanding once the credit is available in our Form 26AS / Annual Tax Statement.</p>
                                                                        <li>
                                                                            Gross TDS Mode –
                                                                        </li>
                                                                        <p>In this mode, the EMI collected is including the TDS portion. Additionally, the customer shall remit at source the tax on interest component with the Income Tax authorities, Government of India. The remitted tax portion will be refunded to the customer once the credit is available in our Form 26AS / Annual Tax Statement.</p>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_three">
                                                        What is TDS Pro Software?
                                                    </button>
                                                    <div id="collapse_pc_in_13_three" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_13_nw">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <ul className="panel_list">
                                                                    <li>In our endeavor to pleasantly exceed your expectations, we have designed a tool “TDS Pro”. This tool assists the customers to manage their tax obligations for all loan contracts.</li>
                                                                    <li>URL: <a target='_blank' href="https://www.update.mercedes-benz-financialservices.in/TDSPRO">https://www.update.mercedes-benz-financialservices.in/TDSPRO</a></li>
                                                                    <li>Username: PAN Number</li>
                                                                    <li>Password: For First time user – Click on Forgot Password and enter the PAN number to receive the password on registered email ID.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_four">
                                                        What are all features available in TDS Pro tool?
                                                    </button>
                                                    <div id="collapse_pc_in_13_four" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_13_nw">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p className='panel_by_head'>Here are some of salient features of the TDS Pro tool:</p>
                                                                <ul className="panel_list">
                                                                    <li>
                                                                        The tool can be accessed using the below link:
                                                                    </li>
                                                                    <li>
                                                                        Customers can link their Tax deduction Account Number (TAN) (by uploading relevant proof) against their PAN available in our records, to have a transparent obligation / requirement on TDS.
                                                                    </li>
                                                                    <li>
                                                                        Customers can view their tax obligation over the life cycle of loan contract.
                                                                    </li>
                                                                    <li>
                                                                        Customers can view and track the live position of the TDS that has been remitted in Form 26AS / Annual Tax Statement.
                                                                    </li>
                                                                    <li>
                                                                        Customers opting for Gross TDS Mode Contracts, can claim their refunds in a simple and efficient way.
                                                                    </li>
                                                                    <li>
                                                                        For customers opting for Net TDS Mode Contracts, once the credit is available in Form 26AS as per the ‘expected TDS’, the loan outstanding dues will be accounted smoothly with the help of the Tool.
                                                                    </li>
                                                                    <li>
                                                                        In case of any mismatch between ‘expected TDS’ and ‘actual TDS remittance’ (appearing in Form 26AS), customers can provide breakup for the TDS remitted.
                                                                    </li>
                                                                    <li className='last_p_ul'>
                                                                        In case of multiple TANs, customer has an option to assign the TANs in the Tool.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="accordion-item">
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_in_13_five">
                                                        Incase of any issue, whom to contact?
                                                    </button>
                                                    <div id="collapse_pc_in_13_five" className="accordion-collapse collapse" data-bs-parent="#accordian_cv_ins_13_nw">
                                                        <div className="accordion-body">
                                                            <div className="panel-body">
                                                                <p>Please feel free to call our toll free number <a href="tel:1800-212-3374"> 1800 212 3374 </a> or email us at  <a href="mbfsindia@mercedes-benz.com"> mbfsindia@mercedes-benz.com </a> with relevant issue details.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item acc_item_m">
                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_pc_2_fourten">
                                        EMI Mode Change
                                    </button>
                                    <div id="collapse_pc_2_fourten" className="accordion-collapse collapse" data-bs-parent="#accordian_pc_2">
                                        <div className="accordion-body">
                                            <div className="panel-body">
                                                <p>Once the contract is activated, changing the EMI from Gross to Netoff or Netoff to Gross is not feasible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FAQ