import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Header_two from "../Header_two";
import Intimation_s_e from "./Intimation_s_e";
import Investor_gustor from "./Investor_gustor";
import Our_company_menu from "./Our_company_menu";
import AuthService from "../../service/authservice";
import { getFileIcon,getFileExtension } from "../CommonService/fileDetails";
const Investor = () => {
  let [DropdownOptions, SetDropdownOptions] = useState({
    Annualreport: true,
    InvestorGrievances: false,
    IntimationToStockExchange: false,
    OtherDocumentsAndPolicies: false,
  });
  const DroppdownOnChange = (event) => {
    SetDropdownOptions({ Annualreport: false, [event.target.value]: true });
  };
  const [loggedIn, setLoggedIn] = useState(false);
  const [financialYear, setfinancialYear] = useState([]);
  const [AnnualReportYears, setAnnualReportYears] = useState([]);
  const[IntimationToSEYears,setIntimationToSEYears]=useState([]);
  const[IntimationYear,setIntimationYear]=useState("All");
  let [file, setFile] = useState("");
  let [fileDownload, SetFileDownload] = useState({
    base64String: "",
    fileType: "",
    fileName: "",
  });
  const [report, Setreport] = useState([
    {
      id: 0,
      reportsTypeId: 0,
      headerText: "",
      fy: "",
      filePath: "",
      fileName: "",
    },
  ]);
  const [AnnualReports, SetAnnualReports] = useState([
    {
      annualReports:[],
      financialReport:[],
      debentureTrustee:[],
      creditRating:[],
    },
  ]);
  const [IntimationToStockExchange,SetIntimationToStockExchange]=useState([]);
  useEffect(() => {
    const mbfsToken=localStorage.getItem("MBFSIUser")
    if(mbfsToken!=null){
      GetAnnualFilesHandler();
      fyDropdownDetails();
      GetAnnualReportYears();
      GetIntimationToSEYears();
    }
    else{
     setTimeout(() => {
      GetAnnualFilesHandler();
      fyDropdownDetails();
      GetAnnualReportYears();
      GetIntimationToSEYears();
      }, 2000)
    }
  }, []);
  let GetAnnualFilesHandler = () => {
    AuthService.GetServiceCall("MbfsReports/AnnualReports")
      .then((res) => {
        SetAnnualReports(res);
      })
      .catch((err) =>SetAnnualReports(null));
  };
  let fyDropdownDetails = () => {
    AuthService.GetServiceCall("MbfsReports/FinancialYears")
      .then((res) => {
        setfinancialYear(res);
      })
      .catch((err) => console.log("Error:", err));
  };

  let SaveFileHandler = (e) => {
    setFile(e.target.files[0]);
  };
  let UploadHandler = () => {
    let formData = new FormData();
    formData.append("FormFile", file);
    AuthService.PostServiceCall("MbfsReports", formData)
      .then((res) => {
        if (res) {
          Setreport({
            ...report,
            fileName: res.filename,
            filePath: res.filePath,
          });
        }
      })
      .catch((err) => console.log("Error:", err));
  };
  let SaveHandler = () => {
    AuthService.PostServiceCall("MbfsReports/AnnualReports", report)
      .then((res) => {
      })
      .catch((err) => console.log("Error:", err));
  };
  let OnChangeDownloadHandler = (arg) => {
    let filename = arg;
    AuthService.GetServiceCall("MbfsReports/DownloadFile?FilePath=" + filename)
      .then((res) => {
        if(res!=undefined){
        SetFileDownload(res);
        }
      })
      .catch((err) => console.log("Error:", err));
  };
  let OnChangeAnnualReportFiler = (e) => {
    let year = e.target.value;
    AuthService.GetServiceCall("MbfsReports/AnnualReports?FYear=" + year)
      .then((res) => {
        SetAnnualReports(res);
      })
      .catch((err) => console.log("Error:", err));
  };
  useEffect(() => {
    if (fileDownload.base64String != null && fileDownload.base64String != "") {
      let a = document.createElement("A");
      a.href =
        `data:${fileDownload.fileType};base64,` + fileDownload.base64String;
      a.download = fileDownload.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [fileDownload]);
  let GetAnnualReportYears = () => {
    AuthService.GetServiceCall("MbfsReports/GetAnnualReportYears")
      .then((res) => {
        setAnnualReportYears(res);
      })
      .catch((err) => setAnnualReportYears(null));
  }
  let GetIntimationToSEYears=()=>{
    AuthService.GetServiceCall("MbfsReports/GetISEYear")
    .then((res) => {
      setIntimationToSEYears(res);
    })
    .catch((err) =>setIntimationToSEYears([]));
  }
  useEffect(()=>{
    GetAnnualFilesHandler();
  },[DropdownOptions])
  // useEffect(()=>{
    
  // },[IntimationYear])
  return (
    <div>
      <Header />
      <Header_two />
      <Our_company_menu />

      <div className="container-fluid invest_rel">
        <div className="container">
          <h1>Investor Relations</h1>
        </div>
      </div>

      <div className="container mb_gap">
        <div className="select_opt_invest">
          <select
            className="form-select frm_select_invest"
            onChange={DroppdownOnChange}
          >
            <option value="Annualreport">Annual reports​</option>
            <option value="InvestorGrievances">Investor Grievances</option>
            <option value="IntimationToStockExchange">
              Intimation To Stock Exchange
            </option>
          </select>
          {DropdownOptions.Annualreport == true && (
            <select
              className="form-select frm_select_invest"
              onChange={(e) => OnChangeAnnualReportFiler(e)}
            >
              <option value="All">All</option>
              {AnnualReportYears!=null &&
                AnnualReportYears.map((items, id) => {
                  return <option value={items}>{items}</option>;
                })}
            </select>
          )}
          {DropdownOptions.IntimationToStockExchange == true && (
            <select
              className="form-select frm_select_invest" onChange={(e)=>setIntimationYear(e.target.value)}
            >
              <option value="All">All</option>
              {IntimationToSEYears &&
                IntimationToSEYears.map((SEYearsitems, SEYearsid) => {
                  return <option value={SEYearsitems}>{SEYearsitems}</option>;
                })}
            </select>
          )}
          {/* <select className="form-select frm_select_invest" disabled>
            <option value="6">2023</option>
            <option value="1" disabled>2022</option>
            <option value="2" disabled>2021</option>
            <option value="3" disabled>2020</option>
            <option value="4" disabled>2019</option>
            <option value="5" disabled>2018</option>
          </select> */}
        </div>
        {DropdownOptions.Annualreport == true && AnnualReports!=null && (
          <div id="one">
            {AnnualReports.annualReports!=null &&(          
               !AnnualReports.annualReports[0]!=undefined&&(
                <div className="annula_report_sec">
                <h3 className="top_name_flt">
                  <span className="ann_rep_txt">Annual Reports</span>
                  { 
                    loggedIn && (
                    <span className="btn_rep_add">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          Setreport({ ...report, reportsTypeId: 1 });
                        }}
                      >
                        Add
                      </button>
                    </span>
                  )}
                </h3>
                <div className="accordion" id="accordion_an">
                {AnnualReports.annualReports.map((items, id) => {
                  return (
                      <div className="accordion-item accordion-item_an" key={id}>
                        <button
                          className="accordion-button ac_btn collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse_O${id}`}
                        >
                          <h3>
                            {items.heading}{" "}
                            <span>{`(${items.report.length} file)`}</span>
                          </h3>
                        </button>
                        <div
                          id={`collapse_O${id}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordion_an"
                        >
                          <div className="accordion-body ar">
                            <h3 className="brd_name">
                              <span>Mercedes-Benz</span> Financial Services
                              India
                            </h3>
                            {items.report && (
                              <>
                                <h1 className="ye_an">
                                  {items.report[0].heading}
                                </h1>
                                {items.report.map((v, id) => {
                                  return (
                                    <>
                                      <p className="dwn_pdf">
                                        <a
                                          onClick={() =>
                                            OnChangeDownloadHandler(v.filePath)
                                          }
                                        >
                                          <i className={`fa-solid ${getFileIcon(v.filePath)}`}></i>
                                          {v.fileName}
                                          <span>{getFileExtension(v.filePath)}</span>
                                        </a>
                                      </p>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                  );
                })}
                </div>
              </div>
              )
            )}
            {AnnualReports.financialReport!=null && (
              AnnualReports.financialReport[0]!=undefined &&(
                <div className="fincial_rep">
                <h3 className="top_name_flt">
                  <span>Financial Results</span>
                  {loggedIn && (
                    <span className="btn_rep_add">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          Setreport({ ...report, reportsTypeId: 2 });
                        }}
                      >
                        Add
                      </button>
                    </span>
                  )}
                </h3>
                <div className="accordion" id="accordion_fr">
                  {AnnualReports.financialReport.map((finItems, id) => {
                    return (
                      <div
                        className="accordion-item accordion-item_an"
                        key={id}
                      >
                        <button
                          className="accordion-button ac_btn collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapsetwo_fr${id}`}
                        >
                          <h3>
                            {finItems.heading}
                            <span>
                              {finItems.report.length == 1
                                ? `(${finItems.report.length} file)`
                                : `(${finItems.report.length} files)`}
                            </span>
                          </h3>
                        </button>
                        <div
                          id={`collapsetwo_fr${id}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordion_fr"
                        >
                          <div className="accordion-body ar">
                            <h3 className="brd_name">
                              <span>Mercedes-Benz</span> Financial Services
                              India
                            </h3>
                            {finItems.report.length == 1 ? (
                              <>
                                <h1 className="ye_an">
                                  {finItems.report[0].heading}
                                </h1>
                                <p className="dwn_pdf">
                                  <a
                                    onClick={() =>
                                      OnChangeDownloadHandler(
                                        finItems.report[0].filePath
                                      )
                                    }
                                  >
                                    <i className={`fa-solid ${getFileIcon(finItems.report[0].fileName)}`}></i>
                                    {finItems.report[0].fileName}
                                    <span>{getFileExtension(finItems.report[0].fileName)}</span>
                                  </a>
                                </p>
                              </>
                            ) : (
                              finItems.report.map((v, id) => {
                                return (
                                  <>
                                    <h1 className="ye_an">{v.heading}</h1>
                                    <p className="dwn_pdf">
                                      <a
                                        onClick={() =>
                                          OnChangeDownloadHandler(v.filePath)
                                        }
                                      >
                                        <i className={`fa-solid ${getFileIcon(v.filePath)}`}></i>
                                        {v.fileName}
                                        <span>{getFileExtension(v.filePath)}</span>
                                      </a>
                                    </p>
                                  </>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              )
            )}
            {AnnualReports.debentureTrustee!=null && (
              AnnualReports.debentureTrustee[0]!=undefined &&(
                <div className="Debenture Trustee">
                <h3 className="top_name_flt">
                  <span>Debenture Trustee</span>
                  {loggedIn && (
                    <span className="btn_rep_add">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          Setreport({ ...report, reportsTypeId: 3 });
                        }}
                      >
                        Add
                      </button>
                    </span>
                  )}
                </h3>
                <div className="accordion" id="accordion_dt">
                  {AnnualReports.debentureTrustee.map((dtItems, idx) => {
                    return (
                      <div className="accordion-item accordion-item_an">
                        <button
                          className="accordion-button ac_btn collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne_dt"
                        >
                          <h3>
                            {dtItems.heading}
                            <span>
                              {dtItems.report.length == 1
                                ? `(${dtItems.report.length} file)`
                                : `(${dtItems.report.length} files)`}
                            </span>
                          </h3>
                        </button>
                        <div
                          id="collapseOne_dt"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordion_dt"
                        >
                          <div className="accordion-body ar">
                            <h3 className="brd_name">
                              <span>Mercedes-Benz</span> Financial Services
                              India
                            </h3>
                            {dtItems.report && (
                              <>
                                <h1 className="ye_an">
                                  {dtItems.report[0].heading}
                                </h1>
                                {dtItems.report.map((v, id) => {
                                  return (
                                    <>
                                      <p className="dwn_pdf">
                                        <a
                                          onClick={() =>
                                            OnChangeDownloadHandler(v.filePath)
                                          }
                                        >
                                          <i className={`fa-solid ${getFileIcon(v.filePath)}`}></i>
                                          {v.fileName}
                                          <span>{getFileExtension(v.filePath)}</span>
                                        </a>
                                      </p>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              )          
            )}
            {AnnualReports.creditRating!=null && (
               AnnualReports.creditRating[0]!=undefined &&(  <div className="Debenture Trustee">
                <h3 className="top_name_flt">
                  <span>Credit Rating</span>
                  {loggedIn && (
                    <span className="btn_rep_add">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          Setreport({ ...report, reportsTypeId: 4 });
                        }}
                      >
                        Add
                      </button>
                    </span>
                  )}
                </h3>
                <div className="accordion" id="accordion_dt">
                  {AnnualReports.creditRating.map((crItems, idx) => {
                    return (
                      <div className="accordion-item accordion-item_an">
                        <button
                          className="accordion-button ac_btn collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsetwo_cr"
                        >
                          <h3>
                            {crItems.heading}
                            <span>
                              {crItems.report.length == 1
                                ? `(${crItems.report.length} file)`
                                : `(${crItems.report.length} files)`}
                            </span>
                          </h3>
                        </button>
                        <div
                          id="collapsetwo_cr"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordion_dt"
                        >
                          <div className="accordion-body ar">
                            <h3 className="brd_name">
                              <span>Mercedes-Benz</span> Financial Services
                              India
                            </h3>
                            {crItems.report && (
                              <>
                                <h1 className="ye_an">
                                  {crItems.report[0].heading}
                                </h1>
                                {crItems.report.map((v, id) => {
                                  return (
                                    <>
                                      <p className="dwn_pdf">
                                        <a
                                          onClick={() =>
                                            OnChangeDownloadHandler(v.filePath)
                                          }
                                        >
                                          <i className={`fa-solid ${getFileIcon(v.filePath)}`}></i>
                                          {v.fileName}
                                          <span>{getFileExtension(v.filePath)}</span>
                                        </a>
                                      </p>
                                    </>
                                  );
                                })}
                              </>
                            )}
                            {/* <h1 className='ye_an'>MBFS Credit Rating Information</h1>
                      <p className='dwn_pdf'><a target="_blank" href="https://www.mercedes-benz-financialservices.in/en/Annual-Report/Debenture-trustee.pdf"><i className="fa-solid fa-file-pdf"></i>Debenture Trustee<span>Download PDF</span></a></p> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>)
            )}
          </div>
        )}
        {DropdownOptions.InvestorGrievances == true &&(
          <div className="innves_guster" id="two">
            <h3 className="top_name_flt">Investor Grievances</h3>
            <Investor_gustor />
          </div>
        )}
        {DropdownOptions.IntimationToStockExchange == true &&(
          <div className="Intimation to Stock Exchange" id="three">
            <h3 className="top_name_flt">Intimation to Stock Exchange</h3>
            <Intimation_s_e year={IntimationYear}/>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
export default Investor;
