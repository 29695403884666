import React from 'react'
const Investor_gustor = () => {
    return (
        <div>
            <div className='container Investor_cnt'>
                <div className='first_addd'>
                    <p>Investor Grievances Redressal Officer​</p>
                    <p><span>Name: </span>Mr. Kamala Vasantha Thiagarajan​</p>
                    <p> <span>Address: ​</span>5th Floor, Plot 8,</p>
                    <p> Baashyam Willow Square 9 & 10,</p>
                    <p> First Street, Thiru ViKa Industrial Estate,</p>
                    <p> Guindy,</p>
                    <p> Chennai - 600032.</p>
                    <p>Tamil Nadu - India</p>
                </div>
                <p><span>Email id:</span> <a href="mailto:mbfsinvestor@mercedes-benz.com">mbfsinvestor@mercedes-benz.com</a>​</p>
                <div>
                    <div className='add_le_1'>
                        <p><b>Level 1</b></p>
                        <p>Any one of the following</p>
                        <p><b>Call us:</b></p>
                        <p><a href="tel:1800-212-3374">Customer Service Centre: 1800 212 3374 / 1800 212 2237 from 09:00 – 18:00 (Mon – Fri) & 09:00 – 13:00 (Sat), except public holidays.</a></p>
                        <p><b>Email Id</b></p>
                        <p><a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a></p>
                        <p>You can also access information related to your account by visiting customer online service portal. COS Link.</p>
                        <p>COS Link: <a href="https://mymbfs.com/" target="_blank">www.mymbfs.com</a></p>
                    </div>
                    <div className='add_le_1'>
                        <p><b>Level 2</b></p>
                        <p>If the complaint is unresolved within 10 working days, please contact our Grievance Redressal Officer, Mr. Gurinder Singh with the reference number given in Level 1: Email id: gurinder_singh.bhullar@mercedes-benz.com​</p>
                        <p>id: <a href="mailto:gurinder_singh.bhullar@mercedes-benz.com"> gurinder_singh.bhullar@mercedes-benz.com</a></p>
                    </div>
                    <div className='or_txt'>
                        (OR)
                    </div>
                    <p className='wri_cus_txt'>Write to Customer Service Department</p>
                    <div className='first_addd two'>
                        <p className='add_b_txt'><b>Registered address:</b></p>
                        <p>Mercedes-Benz Financial Services India Pvt. Ltd.</p>
                        <p>(Formerly known as Daimler Financial Services India Private Limited)</p>
                        <p> 5th Floor, Plot 8, Baashyam Willow Square 9 &10,</p>
                        <p>First Street, Thiru ViKa Industrial Estate, Guindy,</p>
                        <p>Chennai - 600032</p>
                        <p>Tamil Nadu, India.</p>
                    </div>
                    <div className='first_addd two l'>
                    <p className='add_b_txt'> <b>Correspondence Address:</b></p>
                      <p>Mercedes-Benz Financial Services India Pvt. Ltd.</p>
                      <p> (Formerly known as Daimler Financial Services India Private Limited)</p>
                      <p>  E-3, MIDC Chakan, Phase III,</p>
                      <p>  Chakan Industrial Area, Kuruli & Nighoje,</p>
                      <p>  Taluka Khed, Pune - 410501</p>
                      <p> Maharashtra, India</p>
                      <p> Telephone Number: (+91) 21 3567 3000</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Investor_gustor