import { React, useEffect, useState,useRef } from "react";
import "./Download_table.css";
import { NavLink, useNavigate } from 'react-router-dom'
import AddReport from "./AddReports";
import AuthService from "../service/authservice";
import { getFY, getSEYear,getIntimationMonths} from "./CommonService/fileDetails";
import Header from "./Header";
const Download_table = () => {
  const navigate=useNavigate();
  let [DropdownOptions, SetDropdownOptions] = useState({
    Annualreport: true,
    InvestorGrievances: false,
    IntimationToStockExchange: false,
    OtherDocumentsAndPolicies: false,
  });
  const [OtherDocs, SetOtherDocs] = useState([{
      generalMeetingNotice: [],
      others: [],
      liquidity:[],
    },
  ]);
  let [AnnualDropdownOptions, SetAnnualDropdownOptions] = useState("1");
  let[OtherDocumentOptions,SetOtherDocumentOptions]=useState("1");
  const [AnnualReportYears, setAnnualReportYears] = useState([]);
  const [SEYears, SetSEYears] = useState([]);
  const [SEMonths, SetSEMonths] = useState([]);
  const [DeleteItem,SetDeleteItem]=useState("");
  const[FilterSEYear,SetFilterSEYear]=useState("All");
  const[FilterFYYear,SetFilterFYYear]=useState("All");
  const[FilterFYMonth,SetFilterFYMonth]=useState("All");
  const[FilterSEMonth,SetFilterSEMonth]=useState("All");
  const[FilterOthersYear,SetFilterOthersYear]=useState("All");
  const[DelteRes,SetDeleteRes]=useState("");
  const isAnnualMounted = useRef(false);
  const isSEMounted = useRef(false);
  const isOthersMounted = useRef(false);
  let [fileDownload, SetFileDownload] = useState({
    base64String: "",
    fileType: "",
    fileName: "",
  });
  const handlerOtherDocsGetFile = () => {
    AuthService.GetServiceCallToken("MBFSAdmin/GetAuthOtherDocuments?year="+FilterOthersYear)
      .then((res) => {
        SetOtherDocs(res);
      })
      .catch((err) => console.log("Error:", err));
  };
  const [IntimationToStockExchange,SetIntimationToStockExchange]=useState([]);
  // const[ReportType,SetReportType]=useState("");
  const DroppdownOnChange = (event) => {
    SetDropdownOptions({ Annualreport: false, [event.target.value]: true });
    SetFilterSEYear("All");
    SetFilterFYYear("All");
    SetFilterFYMonth("All");
    SetAnnualDropdownOptions("1");
    const selectedValue = event.target.value;
    //GetIntimationToSE();
   //GetAnnualReportsFilesHandler();
   if (selectedValue === 'Annualreport') {
    GetAnnualReportsFilesHandler();
  } else if (selectedValue === 'IntimationToStockExchange') {
    GetIntimationToSE();
  } else if (selectedValue === 'OtherDocumentsAndPolicies') {
    handlerOtherDocsGetFile();
  }
  };
  const AnnualDropdownOnChange=(e)=>{
    SetFilterFYMonth("All");
    // GetAnnualReportsFilesHandler();
    SetAnnualDropdownOptions(e.target.value);
    // SetReportType(e.target.value)
  }
  const OthersDropdownOnChange=(e)=>{
    SetFilterOthersYear("All");
    SetOtherDocumentOptions(e.target.value)
  }
  const [AnnualReports, SetAnnualReports] = useState([
    {
      annualReports: [],
      financialReport: [],
      debentureTrustee: [],
      creditRating: [],
    },
  ]);
  useEffect(() => {
    handlerGet();
  }, []);
  const handlerGet=()=>{
    GetAnnualReportYears();
     GetSEYears();
     GetSEMonths();
     GetAnnualReportsFilesHandler();
     //GetIntimationToSE();
    // handlerOtherDocsGetFile();
  }
  // useEffect(() => {
  //   alert("use effect annal")
  //   GetIntimationToSE()
  //   GetAnnualReportsFilesHandler();
  //   handlerOtherDocsGetFile();
  // }, [FilterSEYear,FilterFYYear,FilterFYMonth,FilterSEMonth,FilterOthersYear]);

  useEffect(() => {
    if (isAnnualMounted.current) {
      GetAnnualReportsFilesHandler();
    } else {
      isAnnualMounted.current = true;
    }
  }, [FilterFYYear,FilterFYMonth]);

  useEffect(() => {
    if (isSEMounted.current) {
      GetIntimationToSE();
    } else {
      isSEMounted.current = true;
    }
  }, [FilterSEYear,FilterSEMonth]);

  useEffect(() => {
    if (isOthersMounted.current) {
      handlerOtherDocsGetFile();
    } else {
      isOthersMounted.current = true;
    }
  }, [FilterOthersYear]);

  useEffect(() => {
    if (fileDownload.base64String != null && fileDownload.base64String != "") {
      let a = document.createElement("A");
      a.href =
        `data:${fileDownload.fileType};base64,` + fileDownload.base64String;
      a.download = fileDownload.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [fileDownload]);
  // useEffect(()=>{
  //   handlerGet();
  // },[DelteRes])
  let GetAnnualReportYears = () => {
    // console.log("GetAnnualReportYears")
    // AuthService.GetServiceCall("MbfsReports/FinancialYears")
    //   .then((res) => {
    //     setAnnualReportYears(res);
    //   })
    //   .catch((err) => console.log("Error:", err));

      setAnnualReportYears(getFY);
  };
  let GetSEYears = () => {
    SetSEYears(getSEYear);
  };
  let GetSEMonths = () => {
    SetSEMonths(getIntimationMonths);
  };
  let GetAnnualReportsFilesHandler = () => {
    AuthService.GetServiceCallToken(`MBFSAdmin/AuthAnnualReports?FYear=${FilterFYYear}&FMonth=${FilterFYMonth}`)
      .then((res) => {
        SetAnnualReports(res);
      })
      .catch((err) => console.log("Error:", err));
  };
  const GetIntimationToSE=()=>{ 
        AuthService.GetServiceCallToken(`MBFSAdmin/AuthIntimationToStockExchange?SEYear=${FilterSEYear}&SEMonth=${FilterSEMonth}`)
      .then((res)=>{SetIntimationToStockExchange(res)})
      .catch((er)=>{console.log(er);}) 
  }
  const handleSEFilterYear=(e)=>{
    SetFilterSEYear(e.target.value);
    SetFilterSEMonth("All");
  }
  const handlerDeleteReport=()=>{
    if(DropdownOptions.Annualreport==true){
      AuthService.GetServiceCallToken('MBFSAdmin/AnnualReportDelete?id='+DeleteItem)
      .then((res)=>{SetDeleteRes(res);handlerGet();SetDeleteItem("")})
      .catch((er)=>{console.log(er);}) 
    }
    else if(DropdownOptions.IntimationToStockExchange==true)
    {
      AuthService.GetServiceCallToken('MBFSAdmin/IntimationSEDelete?id='+DeleteItem)
      .then((res)=>{SetDeleteRes(res);GetIntimationToSE();SetDeleteItem("")})
      .catch((er)=>{console.log(er);}) 
    }
    else if(DropdownOptions.OtherDocumentsAndPolicies==true)
    {
      AuthService.GetServiceCallToken('MBFSAdmin/DeleteOtherDocuments?id='+DeleteItem)
      .then((res)=>{SetDeleteRes(res);handlerOtherDocsGetFile();SetDeleteItem("")})
      .catch((er)=>{console.log(er);}) 
    }
  }
  let OnChangeDownloadHandler = (arg) => {
    let filename = arg;
    AuthService.GetServiceCall("MbfsReports/DownloadFile?FilePath=" + filename)
      .then((res) => {
        if(res!=undefined){
        SetFileDownload(res);
        }
      })
      .catch((err) => console.log("Error:", err));
  };
  const Logout=()=>{
    localStorage.removeItem("User");
    navigate('/');
  }
  const handlerSession=()=>{
    navigate('/');
  }
  return (
    <div>
    <Header/>
      <div className="container-fluid">
        <div className="container">
        <div className="upload_btn mt-4">
         <button onClick={Logout}>Logout</button>
         </div>
          <div className="drop_dwn_sec">
            <select
              className="form-select frm_select_invest"
              onChange={DroppdownOnChange}
            >
              <option value="Annualreport">Annual Reports​</option>
              <option value="IntimationToStockExchange">
                Intimation To Stock Exchange
              </option>
              <option value="OtherDocumentsAndPolicies">
              OtherDocumentsAndPolicies
              </option>
            </select>
            {DropdownOptions.Annualreport === true && (
              <select className="form-select frm_select_invest" onChange={(e)=>AnnualDropdownOnChange(e)}>
                <option value="1">Annual reports</option>
                <option value="2">Financial Results</option>
                <option value="3">Debenture Trustee</option>
                <option value="4">Credit Rating</option>
              </select>
            )}
            {DropdownOptions.OtherDocumentsAndPolicies === true && (
              <select className="form-select frm_select_invest" onChange={(e)=>OthersDropdownOnChange(e)}>
                <option value="1">General Meeting Notice</option>
                <option value="2">Others</option>
                <option value="3">Liquidity Risk Management Framework Disclosures</option>
                <option value="4">Fairpractice Code</option>
                <option value="5">Discontinued Outsourced Vendors</option>
              </select>
            )}
            {DropdownOptions.Annualreport === true && (
              <select className="form-select frm_select_invest" onChange={(e)=>{SetFilterFYYear(e.target.value)}}>
                <option value="All">All</option>
                {AnnualReportYears &&
                  AnnualReportYears.map((annualYears, annualYearsId) => {
                    return <option value={annualYears} key={annualYearsId}>{annualYears}</option>;
                  })}
              </select>
            )}
            {DropdownOptions.OtherDocumentsAndPolicies === true && OtherDocumentOptions =="3" &&  (
              <select className="form-select frm_select_invest" onChange={(e)=>{SetFilterOthersYear(e.target.value)}}>
                <option value="All">All</option>
                {AnnualReportYears &&
                  AnnualReportYears.map((annualYears, annualYearsId) => {
                    return <option value={annualYears} key={annualYearsId}>{annualYears}</option>;
                  })}
              </select>
            )}
            {DropdownOptions.IntimationToStockExchange === true && (
              <select className="form-select frm_select_invest" onChange={(e)=>handleSEFilterYear(e)}>
                <option value="All">All</option>
                {SEYears != null &&
                  SEYears.map((SEYearsitems, SEYears) => {
                    return <option value={SEYearsitems} key={SEYears}>{SEYearsitems}</option>;
                  })}
              </select>
            )}
            {DropdownOptions.OtherDocumentsAndPolicies === true && OtherDocumentOptions =="1" && (
              <select className="form-select frm_select_invest" onChange={(e)=>SetFilterOthersYear(e.target.value)}>
                <option value="All">All</option>
                {SEYears != null &&
                  SEYears.map((SEYearsitems, SEYears) => {
                    return <option value={SEYearsitems} key={SEYears}>{SEYearsitems}</option>;
                  })}
              </select>
            )}
            {DropdownOptions.IntimationToStockExchange === true && FilterSEYear !=="All" && (
              <select className="form-select frm_select_invest" onChange={(e)=>SetFilterSEMonth(e.target.value)}>
                <option value="All">All</option>
                {SEMonths != null &&
                  SEMonths.map((SEMonthsItems, SEMonthsId) => {
                    return (
                      <option value={SEMonthsItems} key={SEMonthsId}>
                        {SEMonthsItems}
                      </option>
                    );
                  })}
              </select>
            )}
            {DropdownOptions.Annualreport === true && AnnualDropdownOptions ==="2" && FilterFYYear !="All" && (
              <select className="form-select frm_select_invest" onChange={(e)=>SetFilterFYMonth(e.target.value)}>
                <option value="All">All</option>
                {SEMonths != null &&
                  SEMonths.map((SEMonthsItems, SEMonthsId) => {
                    return (
                      <option value={SEMonthsItems} key={SEMonthsId}>
                        {SEMonthsItems}
                      </option>
                    );
                  })}
              </select>
            )}
            
            <AddReport onchangeGet={handlerGet} onchangeSe={GetIntimationToSE} onchangeOthers={handlerOtherDocsGetFile} Othersrep={OtherDocumentOptions} Report={AnnualDropdownOptions} YearFy={FilterFYYear} YearSy={FilterSEYear} Month={FilterSEMonth} Dropdown={DropdownOptions} Otheryear={FilterOthersYear} IntiToSEYears={SEYears}/>
          </div>
          <table className="table table_up">
            <thead>
            {DropdownOptions.Annualreport ==true &&
              <tr>
                <th>Heading</th>
                <th>Delete</th>
                <th>Download</th>
              </tr>
            }
            {DropdownOptions.IntimationToStockExchange ==true &&
              <tr>
                <th>Heading</th>
                <th>Year</th>
                <th>month</th>
                <th>Delete</th>
                <th>Download</th>
              </tr>
            }
            {DropdownOptions.OtherDocumentsAndPolicies ==true &&
              <tr>
                <th>Heading</th>
                <th>Year</th>
                <th>Delete</th>
                <th>Download</th>
              </tr>
            }
            </thead>
            <tbody>
              { AnnualReports!=null && AnnualReports.annualReports != null && DropdownOptions.Annualreport ==true && AnnualDropdownOptions =="1" &&
                AnnualReports.annualReports.map(
                  (annualrepItems, annualrepId) => {
                   return(
                    annualrepItems.report!=null &&
                    annualrepItems.report.map((reports,repId)=>{
                     return(<tr key={repId}>
                          <td style={{width:'50%'}}>{reports.heading}</td>
                          <td>
                            <button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(reports.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>
                            <td>
                            <button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(reports.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                    })
                   )
                  }
                )}
                { AnnualReports!=null && AnnualReports.financialReport != null && DropdownOptions.Annualreport==true && AnnualDropdownOptions =="2" &&
                AnnualReports.financialReport.map(
                  (financialrepItems, financialrepId) => {
                   return(
                    financialrepItems.report!=null &&
                    financialrepItems.report.map((finrep,finrepId)=>{
                     return(<tr key={finrepId}>
                          <td style={{width:'50%'}}>{finrep.heading}</td>
                          <td>
                            <button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(finrep.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>
                            <td>
                            <button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(finrep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                    })
                   )
                  }
                )
                }
                { AnnualReports!=null && AnnualReports.debentureTrustee != null && DropdownOptions.Annualreport==true && AnnualDropdownOptions =="3" &&
                AnnualReports.debentureTrustee.map(
                  (debenturerepItems, debenturerepId) => {
                   return(
                    debenturerepItems.report!=null &&
                    debenturerepItems.report.map((dtrep,dtrepId)=>{
                     return(<tr key={dtrepId}>
                          <td style={{width:'50%'}}>{dtrep.heading}</td>
                          <td>
                            <button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(dtrep.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>
                            <td>
                            <button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(dtrep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                    })
                   )
                  }
                )
                }
                { AnnualReports!=null && AnnualReports.creditRating != null && DropdownOptions.Annualreport==true && AnnualDropdownOptions =="4" &&
                AnnualReports.creditRating.map(
                  (creditRatingrepItems, creditRatingrepId) => {
                   return(
                    creditRatingrepItems.report!=null &&
                    creditRatingrepItems.report.map((crrep,crrepId)=>{
                     return(<tr key={crrepId}>
                          <td style={{width:'50%'}}>{crrep.heading}</td>
                          <td>
                            <button className="act_btn">
                              <i className="fa-solid fa-trash" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(crrep.id)}}></i>Delete
                            </button></td>
                            <td>
                            <button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(crrep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                    })
                   )
                  }
                )
                }
                {IntimationToStockExchange!= null && DropdownOptions.IntimationToStockExchange ==true &&
                    IntimationToStockExchange.map((intimationItems,intimationId)=>{
                        return(
                            intimationItems.stockExchange.map((SeItems,SeId)=>{
                             return(
                                SeItems.monthFiles.map((monthItems,monthId)=>{
                                    return(
                                    <tr key={monthId}>
                                     <td style={{width:'50%'}}>{monthItems.heading}</td>
                                     <td>{intimationItems.year}</td>
                                     <td>{SeItems.headingTxt}</td>
                                     <td><button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(monthItems.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>          
                            <td><button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(monthItems.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>
                                )
                                })
                             )
                            })
                        )                 
                })
                }
                {OtherDocs !=null && DropdownOptions.OtherDocumentsAndPolicies==true && OtherDocumentOptions =="1" && OtherDocs.generalMeetingNotice !=null &&
                  OtherDocs.generalMeetingNotice.map((grItems,grId)=>{
                  return(
                  grItems.report.map((grRep,grRepId)=>{
                    return(
                                    <tr key={grRepId}>
                                     <td style={{width:'50%'}}>{grRep.heading}</td>
                                     <td>{grItems.heading}</td>
                                     <td><button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(grRep.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>          
                            <td><button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(grRep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                  }))
                  })
                } 
                { OtherDocs !=null && DropdownOptions.OtherDocumentsAndPolicies==true && OtherDocumentOptions =="2" && OtherDocs.others !=null &&
                   OtherDocs.others[0].report.map((grRepOther,grRepOtherId)=>{
                    return(
                                    <tr key={grRepOtherId}>
                                     <td style={{width:'50%'}}>{grRepOther.heading}</td>
                                     <td>NA</td>
                                     <td><button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(grRepOther.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>          
                            <td><button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(grRepOther.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                  })
                }

                { OtherDocs !=null && DropdownOptions.OtherDocumentsAndPolicies==true && OtherDocumentOptions =="3" && OtherDocs.liquidity !=null &&
                  OtherDocs.liquidity.map((liquidityItems,liquidityId)=>{
                  return(
                    liquidityItems.report.map((grliquidityRep,grliquidityRepId)=>{
                    return(
                                    <tr key={grliquidityRepId}>
                                     <td style={{width:'50%'}}>{grliquidityRep.heading}</td>
                                     <td>{liquidityItems.heading}</td>
                                     <td><button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(grliquidityRep.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>          
                            <td><button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(grliquidityRep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                  }))
                  })
                }

                { OtherDocs !=null && DropdownOptions.OtherDocumentsAndPolicies==true && OtherDocumentOptions =="4" && OtherDocs.others !=null &&
                   OtherDocs.fairpractice[0].report.map((fairpracticeRep,fairpracticeRepId)=>{
                    return(
                                    <tr key={fairpracticeRepId}>
                                     <td style={{width:'50%'}}>{fairpracticeRep.heading}</td>
                                     <td>NA</td>
                                     <td><button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(fairpracticeRep.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>          
                            <td><button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(fairpracticeRep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                  })
                }

                { OtherDocs !=null && DropdownOptions.OtherDocumentsAndPolicies==true && OtherDocumentOptions =="5" && OtherDocs.others !=null && OtherDocs.discontinuedOutsourcedVendors!=null &&
                   OtherDocs.discontinuedOutsourcedVendors[0].report.map((discontinuedOutsourcedVendorsRep,discontinuedOutsourcedVendorsRepId)=>{
                    return(
                                    <tr key={discontinuedOutsourcedVendorsRepId}>
                                     <td style={{width:'50%'}}>{discontinuedOutsourcedVendorsRep.heading}</td>
                                     <td>{discontinuedOutsourcedVendorsRep.heading}</td>
                                     <td><button className="act_btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={()=>{SetDeleteItem(discontinuedOutsourcedVendorsRep.id)}}>
                              <i className="fa-solid fa-trash"></i>Delete
                            </button></td>          
                            <td><button className="act_btn_dwn" onClick={()=>{OnChangeDownloadHandler(discontinuedOutsourcedVendorsRep.filePath)}}>
                              <i className="fa-solid fa-download"></i>Download
                            </button>
                          </td>
                        </tr>)
                  })
                }

            </tbody>
          </table>
        </div>
      </div>
<div className="modal fade" id="deleteModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
      </div>
      <div className="modal-body">
        Are you sure you want to delete this item
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-dark" onClick={handlerDeleteReport} data-bs-dismiss="modal">Yes</button>
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
<div className="modal fade" id="expiryModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
      </div>
      <div className="modal-body">
        Session Expired
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-dark" onClick={handlerSession} data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
    </div>
  );
};
export default Download_table;
