import React from 'react'
import "./Header.css"
import { NavLink } from 'react-router-dom'
import Offcan_menu_mob from './Offcan_menu_mob';

const Header = () => {
  var Head_img = require('../Images/MBFS_Logo-13.png');
  var Head_img_mob = require('../Images/mercede_logo_mob.png');

  var currentdate =new Date();
  var currentYear = currentdate.getFullYear();
  return (
    <div>
      <div className='d-block d-md-none'>
        <Offcan_menu_mob />
      </div>
      <div className='container-fluid Top_hed_div_mn'>
        <div className='container Top_hed_div'>
          <div className='mob_toggle_sh d-flex d-md-none' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample_mob">
            <span className='span_1'></span>
            <span className='span_1'></span>
            <span className='span_1'></span>
          </div>
          <div className='Main_head'>
            <NavLink end className="mob_top_logo" to='/' ><img src={Head_img} /></NavLink>
            <NavLink end className="mob_top_logo_mob" to='/' ><img src={Head_img_mob} /></NavLink>
          </div>
          <div className='copy_write_txt'>
            <p><i className="fa-regular fa-copyright"></i>{currentYear}. Mercedes-Benz Financial Services India Pvt. Ltd. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header