import axios from "axios";
import { Navigate } from 'react-router-dom'
const baseURL = process.env.React_App_baseURL;
const API_URL = process.env.API_URL;
let Currenttoken = "";
let Usertoken = "";
console.log("baseURL: ",baseURL)
const GetServiceCallToken = (apiName) => {
  Currenttoken=GetWithExpiry('User');
  if(Currenttoken==null){
    <Navigate to="/"></Navigate>
  }
   //Currenttoken=JSON.parse(localStorage.getItem('User'))
  return axios
    .get(baseURL + apiName, { headers: {"Authorization" : `Bearer ${Currenttoken}`} })
    .then((response) => {
      return response.data;
    }).catch(err => {
       console.log("err", err)
      // localStorage.clear();
          // <Navigate to="/"></Navigate>
          // window.location.reload();
      if (err.response.data) {
        if (err.response.data === "Invalid Token") {
          window.location.reload();
        }
      }
    });
};
const PostServiceCallToken = (apiName, postData) => {
  Currenttoken=GetWithExpiry('User');
  if(Currenttoken==null){
    <Navigate to="/"></Navigate>
  }
  //Currenttoken=JSON.parse(localStorage.getItem('User'))
  return axios
    .post(baseURL + apiName, postData, { headers: {"Authorization" : `Bearer ${Currenttoken}`}})
    .then((response) => {
      return response.data;
    }).catch(err => {
      // console.log("err", err)
      // localStorage.clear();
      // <Navigate to="/"></Navigate>
      // window.location.reload();
      if (err.response.data) {
        if (err.response.data === "Invalid Token") {
          localStorage.removeItem("User");
          <Navigate to="/"></Navigate>
          window.location.reload();
        }
      }
    });
};
const PostServiceCall = (apiName, postData) => {
 //console.log(baseURL + apiName, postData)
  return axios
    .post(baseURL + apiName, postData)
    .then((response) => {
      return response.data;

    }).catch(err => {
      console.log("error",err)
      let apierror = err.message
      if (err.response.data.errors) {
        apierror = err.response.data.errors[0].msg
      } else if (err.response.data.msg) {
        apierror = err.response.data.msg
      }
      return apierror
    });
};
const GetServiceCall = (apiName) => {
        Usertoken=GetWithExpiry('MBFSIUser');
        if(!Usertoken){
          // alert("token is null")
          Usertoken=createPublicUserToken();
        }
  return axios
    .get(baseURL + apiName, { headers: {"Authorization" : `Bearer ${Usertoken}`} })
    .then((response) => {
        //console.log("process.env",process.env)
        //console.log("baseURL",baseURL)
      //console.log("API_URL",API_URL)
      return response.data;

    }).catch(err => {
      validateUserToken(Usertoken);
        console.log("response.data Error", err)
    });
};
const DeleteServiceCall = (apiName) => {
  return axios
    .delete(baseURL + apiName)
    .then((response) => {
      return response.data;

    }).catch(err => {
      console.log("response.data Error", err)
      let apierror = err.message
     
      return apierror
    });
  };
  const GetWithExpiry=(key)=> {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
        return null;
    }
    const item = JSON.parse(itemString);
    const now = new Date();
    if (item.expiry) {
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
  } else {
    localStorage.removeItem(key);
    return null;
  }   
    return item.value;
}
const SetWithExpiry=(key, value, expirationInHours)=>{
  const now = new Date();
  const expirationTime = now.getTime() + expirationInHours * 60 * 60 * 1000;
  const item = {
      value: value,
      expiry: expirationTime,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
const createPublicUserToken=()=>{
  let token="";
PostServiceCall(`Token?APIKey=dLtGQitI8VEgkttf4NfH8EAQKCCE3qA6`)
  .then((res) => {
      if(res === "UnAuthorized"){
          //let deleteEvent=document.getElementById('deleteAlert');
          //deleteEvent.click();
      } 
      else{
        token=AuthService.SetWithExpiry("MBFSIUser",res,20);
      //localStorage.setItem("MBFSIUser",JSON.stringify(res));       
      }
  })
  .catch((err) => console.log("Error:", err));
  return token;
}
const validateUserToken=(currentToken)=>{
PostServiceCall(`Token/ValidateTokenAsync?token=${currentToken}`)
  .then((res) => {
      if(res === "invalid"){
        createPublicUserToken();
      } 
  })
}
// setWithExpiry('myKey', 'myValue', 10);
// const retrievedValue = getWithExpiry('myKey');
const AuthService = {
  GetServiceCallToken,
  PostServiceCallToken,
  PostServiceCall,
  GetServiceCall,
  DeleteServiceCall,
  GetWithExpiry,
  SetWithExpiry,
  createPublicUserToken
};

export default AuthService;
