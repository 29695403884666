import React from 'react'
import "./Header_two.css"
import { NavLink } from 'react-router-dom'
const Header_two = () => {
  return (
    <div>
      <div className='container-fluid Heder_two_str'>
        <div className='container header_tw_cs'>
          <div className='scn_ment_str'>
            <NavLink to='/Our_company' >Our Company</NavLink>
            <NavLink to='/Our_product' >Our Product</NavLink>
            <NavLink to='/More_info' >For More Info</NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header_two