import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import Our_company_menu from './Our_company_menu'


const Vission = () => {
    return (
        <div>
            <div className='container-fluid vission_mission'>
                {/* <div className='container'>
                    <h1>Vision/Mission Statements</h1>
                </div> */}
            </div>
            <div className='container vission'>
                <h1 className="cnt_us_main_hed vi_hed">Vision/Mission Statements</h1>
                <div className='row vis_mis_f_p'>
                    <div className='col-lg-6 col-12 cnt_1_vs br'>
                        <p className='sub_hed_fr_l_n vis_1'>Our Vision</p>
                        <p className='what_we'>What we want to be?</p>
                        <p className=''>We want to be the first choice provider in financial services for our customers and dealers in partnership with our automotive brands</p>
                    </div>
                    <div className='col-lg-6 col-12 cnt_1_vs'>
                        <p className='sub_hed_fr_l_n vis_1'>Our Mission</p>
                        <p className='what_we'>Why we exist?</p>
                        <p className='cmt_txt'>We are committed to excellent Financial Services that:</p>
                        <ul className='vis_list'>
                            <li>Create value for our brands, dealers, and customers.</li>
                            <li>Contribute to the financial success of Mercedes-Benz.</li>
                            <li>offer opportunities for our employees and communities where we live and work</li>
                        </ul>
                    </div>
                </div>
                <div className='row vis_mis_f_p_tw'>
                    <div className='col-lg-6 col-12 cnt_1_vs br'>
                        <p className='sub_hed_fr_l_n vis_1'>Our Perspectives</p>
                        <p className='what_we'>What we look at?</p>
                        <ul className='vis_list'>
                            <li>Markets & Customers</li>
                            <li>Operational Excellence</li>
                            <li>People</li>
                            <li>Profitability</li>
                        </ul>
                    </div>
                    <div className='col-lg-6 col-12 cnt_1_vs'>
                        <p className='sub_hed_fr_l_n vis_1'>Our Values</p>
                        <p className='what_we'>What we belive in?</p>
                        <ul className='vis_list'>
                            <li>Comitment to Excellence</li>
                            <li>Customer Focus</li>
                            <li>Financial & Social Responsibility</li>
                            <li>Inspired, Empowered & Diverse People</li>
                            <li>Integrity, Openness, Respect</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vission