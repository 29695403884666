import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import More_ifo_menu from './More_ifo_menu'
import "./For_info.css"


const Fradulent = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className='container-fluid fradulant_wrk'>
                <div className='container'>
                    <h1>Fraudulent Call Alert</h1>
                </div>
            </div>
            <div className='container pad_cnt_mob'>
                <h1 className='cnt_us_main_hed'>Fraudulent Call Alert</h1>
                <p className='fra_para_top'>
                    We would like to make you aware of fake calls and emails asking customer to make payment in personal account. Please note our bank account details below for making missed EMI payment.
                </p>
                <ul className='frad_list'>
                    <li>Beneficiary’s Name : Mercedes-Benz Financial Services India Private Limited</li>
                    <li>Beneficiary’s Ac No : MBFS(Contract No)</li>
                    <li>Beneficiary’s Bank Name : JP Morgan Chase Bank, Paranur Branch</li>
                    <li>IFSC Code : CHAS0INBX04</li>
                </ul>
                <p className='frd_dwn_para'>We advise you to never entertain a collection call where you have been asked to make payment in any other bank account, except above.
                    In case you receive any such fraudulent call, kindly report the incident to our customer service team at <a href="tel:1800-212-3374">1800 212 3374 </a>
                     You can also contact our customer service team to validate authenticity of call if you have any suspicion.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default Fradulent