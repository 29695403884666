import React, {useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "./service/authservice";
function ProtectedRoutes() {
  AuthService.GetWithExpiry()
  var User = AuthService.GetWithExpiry('User');
  return (
    <>
      {User != null && User != "" ? (
        <Outlet></Outlet>
      ) : (
        <Navigate to="/"></Navigate>
      )}
    </>
  );
}

export default ProtectedRoutes;
