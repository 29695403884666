import React from 'react'
import { NavLink } from 'react-router-dom'

const Offcan_menu_mob = () => {
    var Head_img_mob_of = require('../Images/mercede_logo_mob.png');
    return (
        <div>

            <div className="offcanvas offcanvas-start off_canvs_mob" id="offcanvasExample_mob" >
                <div className="offcanvas-header off_head">
                    <NavLink end className="mob_top_logo_mob_of" to='/' ><img data-bs-dismiss="offcanvas" src={Head_img_mob_of} /></NavLink>
                    <span className='cls_mark' data-bs-dismiss="offcanvas"><i className="fa-solid fa-xmark"></i></span>
                </div>
                <div className="offcanvas-body off_bdy">
                    <div className="head_one_lik_main">
                        <div className='mob_hed_link_main' data-bs-dismiss="offcanvas">
                            <NavLink className='mob_top_link_main' to='/Our_company' ><span>Our Company</span><i className="fa-solid fa-angle-right"></i></NavLink>
                        </div>
                        <div className='mob_hed_link_main' data-bs-dismiss="offcanvas">
                            <NavLink className='mob_top_link_main' to='/Our_product' ><span>Our Product</span><i className="fa-solid fa-angle-right"></i></NavLink>
                        </div>
                        <div className='mob_hed_link_main last' data-bs-dismiss="offcanvas">
                            <NavLink className='mob_top_link_main' to='/More_info' ><span>For More Info</span> <i className="fa-solid fa-angle-right"></i></NavLink>

                        </div>
                    </div>
                    <div className='cpy_txt_t_one'>
                        <p><i className="fa-regular fa-copyright"></i><span>2023. Mercedes-Benz Financial Services India Pvt. Ltd. All Rights Reserved</span></p>
                    </div>
                </div>
            </div>





            <div className="offcanvas offcanvas-top canvas_top" id="offcanvasTop" >
                <div className="offcanvas-header off_two_hed">
                    <h5 className="offcanvas-title" id="offcanvasTopLabel">Our Company</h5>
                    <i data-bs-dismiss="offcanvas" className="fa-solid fa-xmark clas_mark_can"></i>
                </div>
                <div className="offcanvas-body">
                    <div className="head_one_lik">
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c' to='/Our_company' >About Us</NavLink>
                        </div>
                        {/* <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <NavLink className='our_cmp_mob_c' to='/Our_company/Vission' >Vision/Mission</NavLink>
                        </div> */}
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <NavLink className='our_cmp_mob_c' to='/Our_company/Investor' >Investor Relations</NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c last' to='/Our_company/leadership' >Leadership team</NavLink>
                        </div>
                    </div>
                </div>
            </div>


            <div className="offcanvas offcanvas-top canvas_top" id="offcanvasTop_2" >
                <div className="offcanvas-header off_two_hed">
                    <h5 className="offcanvas-title" id="offcanvasTopLabel">Our Product</h5>
                    <i data-bs-dismiss="offcanvas" className="fa-solid fa-xmark clas_mark_can"></i>
                </div>
                <div className="offcanvas-body">
                    <div className="head_one_lik">
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <a target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/financing-leasing.html" className='our_cmp_mob_c'>STAR Finance</a>
                        </div>
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <a target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/financing-leasing.html" className='our_cmp_mob_c'>STAR Agility +</a>
                        </div>
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <a target="_blank" href="https://www.mercedes-benz.co.in/passengercars/finance/insurance.html"  className='our_cmp_mob_c'>STAR Protect Insurance</a>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <a target="_blank" href="https://mymbfs.com/maintenance"  className='our_cmp_mob_c last' to='/O' >Customer online Service</a>
                        </div>
                    </div>
                </div>
            </div>



            <div className="offcanvas offcanvas-top canvas_top" id="offcanvasTop_3" >
                <div className="offcanvas-header off_two_hed">
                    <h5 className="offcanvas-title" id="offcanvasTopLabel">For More Info</h5>
                    <i data-bs-dismiss="offcanvas" className="fa-solid fa-xmark clas_mark_can"></i>
                </div>
                <div className="offcanvas-body">
                <div className="head_one_lik">
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c' to='/More_info' >Contact Us</NavLink>
                        </div>
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <NavLink className='our_cmp_mob_c' to='/More_info/Legal_notice' >Legal notice</NavLink>
                        </div>
                        <div className='mob_hed_link' data-bs-dismiss="offcanvas">
                            <NavLink className='our_cmp_mob_c' to='/More_info/Cookies' >Cookies</NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c ' to='/More_info/Privacy_s' >Privacy statement</NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c ' to='/More_info/Fradulent' >Fraudulent call alert</NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c ' to='/More_info/Framework_rs' >Resolutions Framework 2.0 </NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c ' to='/More_info/FAQ' >FAQs</NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c ' to='/More_info/SARFAESI_ACT' >Assets possessed under SARFAESI</NavLink>
                        </div>
                        <div className='mob_hed_link ' data-bs-dismiss="offcanvas">
                            <NavLink end className='our_cmp_mob_c last' to='/More_info/Other_document' >Other Documents And Policies</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offcan_menu_mob