import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Header_two from '../Header_two'
import "./For_info.css"
import More_ifo_menu from './More_ifo_menu'


const Framework_rs = () => {
    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className='container pad_cnt_mob'>
                <h1 className='cnt_us_main_hed'>Resolution Framework 2.0 for COVID-19 related stress – Guidelines</h1>
                <div className='frm_str'>
                    <p>The resurgence of the fresh Covid-19 wave has put many MSME, individuals and small businesses under stress.</p>
                    <p>To provide support to such customers hit by the wave 2 of the coronavirus pandemic, Mercedes-Benz Financials Services India Pvt Ltd. (“MBFS”) has framed a policy pursuant to the resolution plan for the loans in line with the Covid-19 relief measures announced by the Reserve Bank of India on May 5th, 2021.</p>
                    <p>In these trying times, MBFS offers a helping hand by extending relief as per RBI Resolution Framework 2.0 dated May 5th, 2021. If you are under financial stress caused by the Covid Second wave, you may opt for restructuring of your account.
                        Restructuring under the proposed framework may be invoked up to September 30, 2021.</p>
                </div>
                <div className='frm_str_2'>
                    <p className='sub_hed_fr_l_n'>Eligible borrower:</p>
                    <p>Small businesses, including those engaged in retail and wholesale trade & Micro, small and medium enterprises as on March 31, 2021 (as if applicable )</p>
                    <p>Borrowers who were classified as “Standard” as of March 31, 2021, pursuant to the said RBI guidelines, with aggregate exposure from all financial institutions of not more than Rs.50 crore can apply under Resolution Framework 2.0. The exposure would require adequate evidence/certification<span className='blc_str'>*</span></p>
                    <p>Borrower should not have been classified as restructured in the last one year from Apr 1, 2020 to Mar 31, 2021</p>
                </div>
                <div className='rp_plan'>
                    <p className='sub_hed_fr_l_n'>Resolution Plan (RP):</p>
                    <p>The key features of the RP are as under:</p>
                </div>
                <ul className='rs_plan_lst'>
                    <li>a. Resolution under this framework may be invoked not later than Sep 30, 2021 and must be implemented within 90 days from the date of invocation.</li>
                    <li>b. The RP implemented under this window may inter alia include rescheduling of payments, conversion of any interest accrued. Sanctions, granting of moratorium etc. based on an assessment of income streams of the borrower. However, compromise settlements are not permitted as an RP for this purpose</li>
                    <li>c. Borrowers who have availed no moratoria or moratorium of less than two years and/or extension of residual tenor by a period of less than two years under Resolution Framework 1.0 or any borrower who approaches for resolution under Resolution Framework 2.0 would be eligible for the following, under the proposed resolution framework:</li>
                </ul>
                <ul className='rs_paln_yer'>
                    <li>Avail/increase the moratorium to a maximum period of two years</li>
                    <li>Extension of residual tenure by a maximum period of two years</li>
                </ul>
                <p>If an RP is implemented in adherence to the provisions of this policy and the RBI resolution framework 2.0 , the asset classification may be retained as standard. Each application will be reviewed and MBFS reserve rights to grant Resolution Plan as per MBFS restructuring guidelines and framework.</p>
                <p>The decision on the application shall be communicated in writing to the applicant by the lending institutions within 30 days of receipt of such applications</p>
                <p><span className='condit_spn'>*</span>Conditions Apply</p>
                <p>Customer may reach out to MBFS Customer Service team to apply for Resolution Framework. Details mentioned below:</p>
                <ul className='cnt_us_lis'>
                    <li>E-Mail : <a href="mailto:mbfsindia@mercedes-benz.com">mbfsindia@mercedes-benz.com</a></li>
                    <li>Contact Number : 1800-212-3374</li>
                </ul>
                <p>Customer may reach out to Customer Grievance officer for any grievance. Details mentioned below:</p>
                <ul className='cns_lst_lis'>
                  <li>GRO Name : Mr. Gurinder Singh Bhullar.</li>
                  <li>E-Mail : <a href="mailto:mbfsigrievanceofficer@mercedes-benz.com"> mbfsigrievanceofficer@mercedes-benz.com</a></li>
                  <li>Contact Number : +91 2135 - 637426</li>
                </ul>
            </div>
            <Footer />
        </div>
    )
}

export default Framework_rs