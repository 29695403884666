// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.bot_chat {
    position: fixed;
    bottom: -10px;
    right: -20px;
    width: 160px;
    cursor: pointer;
}

.frame_over_on {
    width: 420px;
    height: 480px;
    border-radius: 10px;
    border: 0;
}

.frame_fram {
    position: fixed;
    bottom: 5%;
    right: 8%;
    display: none;
}

.frame_fram.adding_animate {
    display: block;
    animation: zoomin .3s cubic-bezier(.82, 1, .58, 1);
}
i.fa-solid.fa-xmark.xmark {
    position: absolute;
    right: 20px;
    top: 12px;
    background-color: #fff;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    cursor: pointer;
}
@keyframes zoomin {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media(max-width:767px) {
    img.bot_chat {
        bottom: -1px;
        right: -6px;
        width: 30%;
    }
    .frame_over_on{
        width: fit-content;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Chat_bot/Chat_bot.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,UAAU;IACV,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,kDAAkD;AACtD;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iDAAiD;IACjD,yFAAyF;IACzF,eAAe;AACnB;AACA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;;IAEA;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;QACX,UAAU;IACd;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["img.bot_chat {\n    position: fixed;\n    bottom: -10px;\n    right: -20px;\n    width: 160px;\n    cursor: pointer;\n}\n\n.frame_over_on {\n    width: 420px;\n    height: 480px;\n    border-radius: 10px;\n    border: 0;\n}\n\n.frame_fram {\n    position: fixed;\n    bottom: 5%;\n    right: 8%;\n    display: none;\n}\n\n.frame_fram.adding_animate {\n    display: block;\n    animation: zoomin .3s cubic-bezier(.82, 1, .58, 1);\n}\ni.fa-solid.fa-xmark.xmark {\n    position: absolute;\n    right: 20px;\n    top: 12px;\n    background-color: #fff;\n    height: 25px;\n    width: 25px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */\n    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n    cursor: pointer;\n}\n@keyframes zoomin {\n    0% {\n        transform: scale(0);\n        opacity: 0;\n    }\n\n    100% {\n        transform: scale(1);\n        opacity: 1;\n    }\n}\n\n@media(max-width:767px) {\n    img.bot_chat {\n        bottom: -1px;\n        right: -6px;\n        width: 30%;\n    }\n    .frame_over_on{\n        width: fit-content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
