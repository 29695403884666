import {React,useState} from 'react'
import './Login_form.css'
import { NavLink, useNavigate } from 'react-router-dom'
import AuthService from '../service/authservice'

const Login_form = () => {
    const navigate=useNavigate();
    localStorage.removeItem("User");
    var Head_img_frm = require('../Images/MBFS_Logo-13.png');
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          SigninHandler();
        }
      };
    let SigninHandler=()=>{
        if(username == "")
        {
            document.getElementById('deleteAlert');
        }
        else if(password =="")
        {
            document.getElementById('deleteAlert');
        }
        else{
        AuthService.PostServiceCall(`Token?userName=${username}&userPassword=${password}`)
        .then((res) => {
            if(res === "UnAuthorized"){
                let deleteEvent=document.getElementById('deleteAlert');
                deleteEvent.click();
            }
            else{
                AuthService.SetWithExpiry("User",res,20);
            //localStorage.setItem("User",JSON.stringify(res));
            navigate('/dashboard/Home');
            }
        })
        .catch((err) => console.log("Error:", err));
    }
    }
    return (
        <div>
            <div className="modal fade" tabIndex="-1" id="deleteModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
      </div>
      <div className="modal-body">
      User UnAuthorized
      </div>
      <div className="modal-footer">
        <button type="button" data-bs-dismiss="modal" className="btn btn-dark">OK</button>
      </div>
    </div>
  </div>
</div>
            <div className='container-fluid frm_bg'>
                <div className='nav_img'>
                    <NavLink end className="mob_top_logo_frm" to='/' ><img src={Head_img_frm} /></NavLink>
                </div>
                <div className='container'>
                    <div className='form_strt'>
                        <h3>Welcome Back</h3>
                        <div className='frm_skelton'>
                            <div className='email_dv'>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Username</label>
                                <input type="text" className="form-control" id="UsernameTxt" onChange={(e)=>{setusername(e.target.value)}} value={username} onKeyDown={handleKeyPress}/>
                            </div>
                            <div className='email_dv'>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Password</label>
                                <input type="password" className="form-control" id="PasswordTxt" onChange={(e)=>{setpassword(e.target.value)}} value={password} onKeyDown={handleKeyPress}/>
                            </div>
                            <div className='sign_btn_dv'>
                                <button className='sign_btn' onClick={SigninHandler}>Sign In</button>
                                <input type='button' hidden id='deleteAlert' data-bs-toggle="modal" data-bs-target="#deleteModal"/>
                            </div>
                        </div>
                        {/* <p className='f_y_p_txt'>Forgot your password?</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login_form